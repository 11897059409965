@import '../../../../../components/GlobalStyles/GlobalStyles.scss';

.dropdown-toggle {
    position: relative;
    height: 100%;
    min-width: unset;
    font-size: 1.4rem;
    background-color: #fff !important;
    color: #344563;
    padding: 4px 8px;
    font-weight: 600;
    font-style: bold;
    border: none;
}

.dropdown-toggle::after {
    display: none;
}
.menu-horizontal-header__btn {
    & button:hover {
        color: unset !important;
        background-color: unset !important;
        border-color: unset !important;
    }

    & button:focus {
        color: unset !important;
        background-color: unset !important;
        border-color: unset !important;
        outline: none !important;
        box-shadow: unset !important;
    }
}

.menu-horizontal-header__btn-active {
    height: 100%;
    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 4px;
        right: 4px;
        height: 3px;
        // background-color: $color-primary;
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
    }
}

.name-active,
.icon-active {
    color: $color-primary;
}

.dropdown-item {
    // padding: 6px 10px;
    font-size: 1.4rem;
    cursor: pointer;

    &:hover {
        color: $color-primary;
        background: $background-color-hover;
    }
}

.dropdown-item_projectActive {
    color: $color-primary;
}

.menu-horizontal-header__btn-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    &:hover .menu-header-icon,
    &:hover {
        color: $color-primary;
        background-color: rgba(222, 235, 255, 0.9);
    }
}

.menu-dropdown-projects {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
}

.checkbox-project-text {
    margin-left: 8px;
    cursor: pointer;
}

.glo-position-relative {
    position: relative;
}

.glo-position-absolute {
    position: absolute;
    transition: translate(0px, 58px);
    top: 52px;
    z-index: 10000;
    min-width: 10rem;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    max-height: calc(100vh - 60px);
    overflow: auto;
}
.glo-display-flex {
    display: flex;
}
.glo-btn-item-project {
    border-radius: unset;
    width: 100%;
    text-align: start;
    justify-content: flex-start;
    padding: 6px;
    background: #fff;
    font-size: 14px;
    font-weight: unset;
}
@media screen and (min-width: 375px) and (max-width: 1000px) {
    .glo-btn-project-mobile {
        display: none;
    }
    .glo-position-absolute {
        top: 35px;
    }
}
.glo-border-project {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0.5rem 0;
}
