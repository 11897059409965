// @import '../../../../components/GlobalStyles/GlobalStyles.scss';
@import '../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 14px 42px 0;
}

.wrapper-content {
    padding: 10px;
}

.glo-row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.title-underlined {
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    line-height: 1.2;
    margin: 0px;
}

.title-underlined::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 90px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.statis-flex-end {
    display: flex;
    justify-content: flex-end;
}

.table-content-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
}

.statistical_table-thread {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    font-weight: 600;
}

.statis-body:hover {
    background-color: #f1f7fd;
}

.statis-body {
    margin-bottom: 5px;
    border-left: 3px solid #0065ff;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.statis-name {
    border-right: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sty-border {
    border-bottom: 1px solid #d9d9d9;
    padding: 6px;
}

.sty-border:last-child {
    border-bottom: none;
}

.cs-font-weight {
    font-weight: 600;
}

.total-color {
    color: #0065ff;
}

.total-detail-color {
    color: #666666;
}

//table head
.list-statistical-username {
    width: 15%;
}

.list-statistical-level {
    width: 12.5%;
}

.list-statistical-task-quantity {
    width: unset;
}

//table body
.status-border {
    padding-left: 5px;
    border-right: 1px solid #d9d9d9;
    // border-bottom: 1px solid #d9d9d9;
}
.status-border:not(:last-child) {
    // border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.flex {
    display: flex;
}

.statis-name {
    width: 15%;
}

.list-statistical-item-username {
    width: 100%;
}

.statis-total {
    width: 85%;
}

.list-statistical-item-level {
    width: 10%;
}

.list-statistical-item-task-quantity,
.list-statistical-item-total-score,
.list-statistical-item-total-bug,
.list-statistical-item-total-point,
.list-statistical-item-average-score {
    width: 18%;
}

.statistical-pagination {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
}

@media screen and (min-width: 375px) and (max-width: 1400px) {
    .wrapper {
        padding: 14px 15px;
    }

    .statistical-table-content {
        width: 100%;
        overflow: auto;
    }

    .statistical-table {
        width: 1800px;
    }

    .list-statistical-task-quantity,
    .list-statistical-item-task-quantity .list-statistical-total-score,
    .list-statistical-item-total-score .list-statistical-average-score,
    .list-statistical-item-average-score .list-statistical-total-bug,
    .list-statistical-item-total-bug,
    .list-statistical-total-point,
    .list-statistical-item-total-point {
        width: 270px;
    }
}

.btn-search-statistical {
    width: 80px;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    background: #0052cc;
}

.btn-this-week {
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    background: #0052cc;
}

.txt-notice {
    color: red;
}

.text-flex {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .res-title {
        width: 30%;
    }

    .res-search {
        width: 70%;
    }
}

.icon-copy {
    padding-top: 4px;
    padding-right: 8px;
    cursor: pointer;

    &:hover {
        color: #0052cc;
    }
}

.task-item {
    cursor: pointer;
    &:hover {
        color: #0052cc;
    }
}
