@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Open+Sans:wght@500;600;700&display=swap');

$text-color: #090e16;
$color-primary: #0052cc;
$width-sidebar: 6rem;
$height-header: 5.6rem;
$background-color-hover-menu-item: #091e4214;
$font-family: 'Open Sans', 'Montserrat', sans-serif;
$color-border-table: #dfe1e6;
$icon-size-priority: 2rem;
$color-high: #ff5630;
$color-medium: #ffab00;
$color-low: #0065ff;
$color-tag-inProgress: #8dc2f8;
$color-tag-resolved: #f3c788;
$color-tag-new: #f19a91;
$color-tag-closed: #8ad6a3;
$color-tag-pending: #ff7f00;
$color-tag-testing: #00ff99;
$color-tag-test-done: #ff00ff;
$color-btn-new: #a1221c;
$color-btn-open: #0747a6;
$color-btn-close: #2c363c;
$color-btn-pending: #cbbd08;
$color-btn-resolve: #f38703;
$color-btn-testing: #65b1b1;
$color-btn-fixbug: #45cb70;
$color-btn-testfail: #dba124;
$background-btn-new: #ff00003d;
$background-btn-inProgress: #deebff;
$background-btn-close: #ededed;
$background-btn-testing: #e3fcef;
$background-btn-start: #ffed0036;
$background-btn-resolve: #f3870326;
$background-btn-TestDone: #f906b00f;
$background-btn-Pending: #ffed0036;
$background-btn-Testing: #65b1b159;
$background-btn-Fixbug: #00f5502e;
$background-btn-testfail: #f7d385;
$background-color-hover: rgba(9, 30, 66, 0.08);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    font-size: 62.5%;
}
@media screen and (min-width: 375px) and (max-width: 1000px) {
    html {
        font-size: 90%;
    }
}
body {
    font-family: $font-family;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    color: $text-color;
    font-weight: 500;
}

button,
input,
[tabindex] {
    border: none;
    outline: none;
}

a[href] {
    text-decoration: none;
}

//swal
h2#swal2-title {
    font-size: 20px !important;
}

div#swal2-html-container {
    font-size: 16px !important;
    align-items: center !important;
}

button.swal2-confirm.swal2-styled.swal2-default-outline {
    font-size: 13px !important;
}

button.swal2-cancel.swal2-styled.swal2-default-outline {
    font-size: 13px !important;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {
    width: 400px !important;
    min-height: 250px !important;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 50px !important;
    font-size: 15px !important;
}

.swal2-container {
    z-index: 900000 !important;
}

/* table use mui ui, delele outline */
.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: unset !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: unset !important;
}

.css-204u17-MuiDataGrid-main {
    font-size: 14px;
}

.MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
}

.react-datepicker {
    font-size: 1em;
}
.react-datepicker__header {
    padding-top: 0.8em;
}
.react-datepicker__month {
    margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}
.react-datepicker__current-month {
    font-size: 1em;
}
.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.rdw-link-modal {
    height: 235px !important;
}

.custom-des-tool-link .rdw-link-modal {
    left: -152px !important;
}

.rdw-embedded-modal {
    height: 210px !important;
    // width: 230px;
}

@media screen and (min-width: 375px) and (max-width: 575px) {
    .res-description .rdw-embedded-modal {
        left: -35px !important;
    }

    .res-description .rdw-emoji-modal {
        left: -72px;
    }

    .res-description .rdw-image-modal {
        left: -110px;
    }

    .res-editor-cmt .rdw-image-modal {
        left: -30px !important;
    }

    .res-dialog .css-2rbg70-MuiPaper-root-MuiDialog-paper {
        margin: 15px !important;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        margin: 15px !important;
    }
}

.sc-himrzO.hzmQfP {
    width: 100% !important;
}

.hzmQfP {
    grid-gap: 21px !important;
}

.infinite-scroll-component__outerdiv {
    padding: 0px !important;
}

textarea {
    resize: none;
}

textarea:disabled {
    background-color: #f9f9f9 !important;
}
