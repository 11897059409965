@import '../../../../components/GlobalStyles/GlobalStyles.scss';

// .wrapp-control-dropdown {
//     margin-right: 12px;
// }
.dropdown-filter-relative {
    position: relative;
}

.dropdown-filter .btn-dropdown-filter {
    padding: 0;
    background: #fff;
    border: none;
    outline: none;
    color: #000;
}

.dropdown-filter .dropdown-toggle::after {
    display: none;
}

.btn-dropdown-filter:focus {
    box-shadow: none !important;
}

ul.drop-main-menu {
    min-width: 160px;
    max-height: 567px;
    min-height: 114px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
    border: none;
}

button.btn-control-dropdown {
    display: flex;
    justify-content: flex-start;
    padding: 6px 10px;
    background: none;
    width: 180px;
    height: 36px;
    color: #172b4d;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    text-align: left;
    font-family: 'Circular Std', sans-serif;
}

button.btn-control-dropdown:hover {
    background: $background-color-hover-menu-item;
    cursor: pointer;
    border-radius: 99px;
}

button.btn-control-dropdown span {
    // margin-right: 20px;
}

//dialog
.custom-title-control {
    font-size: 18px !important;
    .custom-title-control-icon {
        margin-right: 15px;
    }
}

.move-project-content {
    min-height: 60px;
}

.hn-custom-project {
    display: flex;
    flex-direction: row;
    align-items: center;
}

label.lbl-move-project {
    width: 100px;
    padding-top: 10px;
}

select#selectMoveProject {
    width: calc(100% - 100px);
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 6px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}

.hn-no-milestone {
    padding: 15px 0px 0px 5px;
}

.milestone-ipRadio {
    margin-right: 5px;
}

tr.milestone-table-tr th {
    border-color: #858585 !important;
}

// ul#res-scroll-control {
//     bottom: 27px;
// }
