@import '../../../../components/GlobalStyles/GlobalStyles.scss';
.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.error-message {
    color: $color-high;
    font-size: 1.4rem;
    padding-left: 10px;
}

.hn-color-red {
    color: $color-high;
}
