@import '../../../components/GlobalStyles/GlobalStyles.scss';

.wrapper-sidebar {
    width: $width-sidebar;
    min-height: 100%;
    position: fixed;
    background-color: #fff;
    transition: 0.5s;
}

.sidebar-header_info-project {
    padding: 56px 8px 0 18px;
    display: flex;
    align-items: center;
}

.sidebar-header_info-project__img {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    border-radius: 4px;
}

.sidebar-header_info-project__detail {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.sidebar-header_info-project__detail h2 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #42526e;
}

.sidebar-header_info-project__name {
    font-size: 1.3rem;
    color: #42526e;
}

.sidebar-header_wapper-create {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 8px;
    cursor: pointer;
}
.sidebar-header_wapper-create-border {
    background: rgb(231, 229, 229);
    border-radius: 99px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgb(185, 183, 183);
    }
}
// Menu sidebar
.sidebar-menu {
    padding-top: 68px;
    // &::before {
    //     content: '';
    //     border-radius: 1px;
    //     left: 8px;
    //     right: 8px;
    //     height: 2px;
    //     background-color: #f0f0f0;
    //     position: absolute;
    //     display: block;
    //     z-index: 2;
    // }
}

.sidebar-menu-list {
    margin: 0 8px;
}

.sidebar-menu-list__item {
    display: flex;
    text-align: left;
    padding: 0px 10px;
    height: 41px;

    &:hover {
        background: $background-color-hover-menu-item;
        cursor: pointer;
    }
}

.sidebar-menu-list__item-icon {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 1.9rem;
}

.sidebar-menu-list__item-text {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #051022;
    font-weight: 600;
}

.item-active {
    position: relative;
    background-color: $background-color-hover;

    &::before {
        content: '';
        position: absolute;
        height: 16px;
        width: 4px;
        left: 0px;
        top: 12px;
        background: #0052cc;
        border-radius: 0px 1.998px 1.998px 0px;
    }
}

.icon-active {
    color: #0052cc;
}
.glo-btnMenu-mobile {
    display: none;
}
.glo-menu-mobile {
    display: block;
}
.glo-menu-mobile-block {
    display: none;
}

.menu-custom-witdth-click {
    width: 60px;
    padding: 0px 18px;
}

@media screen and (min-width: 375px) and (max-width: 1000px) {
    .glo-btnMenu-mobile {
        display: block;
        padding: 5px 0;
    }
    .glo-menu-mobile {
        display: none;
    }
    .wrapper-sidebar {
        width: 100%;
        min-height: 100%;
        position: unset;
        background-color: #fff;
    }
    .sidebar-menu {
        padding-top: 5px;
    }
    .glo-position-relative {
        position: relative;
    }
    .glo-menu-mobile-block {
        display: block;
        position: absolute;
        top: 45px;
        left: 0px;
        z-index: 1000;
        background: #fff;
        width: 100%;
    }
}

.btn-icon-arrow {
    width: 24px;
    height: 24px;
    padding: 0px;
    position: absolute;
    top: 32px;
    left: 47px;
    background-color: #0052cc;
    border: 0px;
    border-radius: 50%;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: #6b778c;
    cursor: pointer;
    outline: 0px;
    transform: translate3d(0px, 0, 0);
    transition: 0.4s all ease-in-out;

    &:hover {
        background-color: #5488d6;
    }
}

.btn-icon-left-arrow {
    width: 24px;
    height: 24px;
    padding: 0px;
    position: absolute;
    top: 32px;
    left: 47px;
    background-color: #0052cc;
    border: 0px;
    border-radius: 50%;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: #6b778c;
    cursor: pointer;
    outline: 0px;
    // transition-delay: 0.7;
    transform: translate3d(180px, 0, 0);
    transition: 0.7s all ease-in-out;

    &:hover {
        background-color: #5488d6;
    }
}

.icon-arrow {
    height: 24px;
    display: flex;
    color: #ffffff;
}
