@import '../../../../components/GlobalStyles/GlobalStyles.scss';

.create-project-action {
    display: flex;
    justify-content: flex-end;
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.dialog-create-project-content {
    width: 100%;
    padding: 0px 20px;
}

.hn-color-red {
    color: $color-high;
}

.lbl-name_add-user {
    padding-left: 7px;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

@media screen and (min-width: 375px) and (max-width: 576px) {
    .dialog-create-project-content {
        padding: 0px 10px;
    }
}

.glo-border-description-project {
    border: 1px solid #dfe1e6;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    border-radius: 0.3rem;
}

.width-assign-to {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.height-des-project {
    min-height: 100px;
}

.wrap-btn-team {
    width: 170px;
    display: flex;
    justify-content: space-around;
}

.label-team {
    margin-left: 4px;
    cursor: pointer;
}
