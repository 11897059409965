.wrapper {
    display: flex;
    flex-direction: column;
    min-width: 160px;
    padding-top: 8px;
    max-height: 567px;
    min-height: 114px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
}
