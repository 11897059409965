@import '../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 56px 42px 42px;
}

.project-cart-create {
    width: 100%;
    height: auto;
    min-height: 240px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    border-top: 5px solid #1976d2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    overflow: hidden;
}

.project-icon-create {
    font-size: 64px;
    width: 100px;
    height: 100px;
    color: #1976d2;
    margin: 0 auto;
}

.project-txt-create-new {
    margin: 0 auto;
}

.project-new {
    padding-top: 15%;
    cursor: pointer;
}

.project-created {
    cursor: pointer;
}

.project-cart-content {
    padding: 20px 15px 0px 15px;
}

.project-img-project {
    width: 85px;
    height: 85px;
    background: #deebff;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    border-radius: 100%;
}

.project-img-project img {
    padding: 22px;
    object-fit: fill;
    image-rendering: pixelated;
    object-position: center;
}

p.project-short-name {
    max-width: 100px;
    margin: 0px auto;
    font-weight: 600;
    text-align: center;
    padding: 6px 0px;
    margin-top: 5px;
    border: 1px solid #deebff;
}

.project-hr {
    color: #1976d2;
}

.project-cart-footer {
    display: flex;
    justify-content: space-between;
}

.project-date-create {
    padding-left: 10px;
    // width: 240px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.project-active,
.project-deactive {
    margin-right: 10px;
    padding: 3px 7px;
    border-radius: 6px;
    height: 30px;
}

.project-active {
    background: rgba(0, 245, 80, 0.2);
    color: #45cb70;
}

.project-deactive {
    background: rgba(255, 0, 0, 0.2);
    color: #a1221c;
}

.project-control {
    bottom: -42px;
    display: none;
    opacity: 0;
}

.project-button-control {
    color: #fff !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
}

.project-cart-create:hover .project-control {
    display: block;
    position: absolute;
    background: #1976d2;
    bottom: 0;
    width: 100%;
    min-height: 60px;
    padding: 0px 10px;
    opacity: 1;
    transition: all 0.8s ease;
}

.project-pagination {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
}

.project-txt-notice {
    display: flex;
    justify-content: center;
    height: 240px;
    align-items: center;
    color: red;
}

.res-control-project {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    .wrapper {
        padding: 20px 25px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .project-cart-create {
        height: 260px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .project-cart-create {
        height: 310px;
    }

    .project-cart-create:hover .project-control {
        height: 90px;
    }

    .project-control {
        padding-top: 10px;
    }

    .project-new {
        padding-top: 40%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .project-cart-create {
        height: 250px;
    }

    .project-cart-create:hover .project-control {
        height: 80px;
    }
}

.btn-edit-center {
    width: 100%;
    min-height: 60px;
}
