@import '../../../../components/GlobalStyles/GlobalStyles.scss';

//filter
.dropdown-filter .btn-dropdown-filter,
.dropdown-groupby .btn-dropdown-groupby,
.dropdown-sortby .btn-dropdown-sortby {
    padding: 6px 10px;
    font-size: 14px;
    background: #0052cc;
    border: none;
}
.dropdown-groupby .btn-dropdown-groupby,
.dropdown-sortby .btn-dropdown-sortby {
    margin-right: 25px;
}

.btn-dropdown-filter svg,
.dropdown-groupby svg,
.dropdown-sortby svg {
    margin: 1px 4px 4px 4px;
    font-size: 16px;
}

.dropdown-filter .dropdown-toggle::after,
.dropdown-groupby .dropdown-toggle::after {
    display: none;
}

.dropdown-filter-relative {
    position: relative;
}

ul.main-menu {
    font-size: 14px;
    position: absolute;
    width: 114px;
}

ul.main-menu-filter li {
    padding: 2px;
}

.sub-menu-wp {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(-210px, 0px);
}

.sub-menu-wp .sub-menu {
    background: #fff;
    border: 1px solid #d9d9d9;
    list-style: none;
    width: 200px;
    max-height: 390px;
    min-height: 220px;
    overflow-y: auto;
    padding: 15px 20px;
}

.sub-menu-wp .sub-menu li {
    padding: 5px;
}

.orangescrum-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.select-task__btn {
    display: flex;
    align-items: center;
    margin-right: 18px;
    padding: 4px 10px;
    background: #fff;
    border: 1px solid $color-border-table;
    border-radius: 4px;
    color: #000;
    font-weight: 500;

    &:hover {
        background: #091e4214;
    }
}
.select-task__btn-active {
    border: 1px solid $color-primary;
}

.select-task__btn-icon {
    margin-right: 6px;
}

.btn-reload,
.btn-childTask {
    background: none;
    font-size: 2.2rem;
    cursor: pointer;
    &:hover {
        color: $color-primary;
    }
}

.btn-custom-fil {
    font-size: 13px !important;
    color: #000 !important;
    justify-content: flex-start !important;
    padding-left: 20px !important;
    text-transform: capitalize !important;
    border-radius: unset !important;
}

.custom-input-time {
    border: 1px solid #d9d9d9 !important;
    padding: 5px 0px 3px 8px !important;
    margin: 8px 0px !important;
}

.btn-before-due-date,
.btn-before-status,
.btn-before-priority,
.btn-before-assign-to,
.btn-before-type {
    position: relative;
}

.btn-before-due-date:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

.btn-before-status:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

.btn-before-priority:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}
.btn-before-assign-to:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

.btn-before-type:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

.btn-before-close-date:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

.btn-before-created-date:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 5px;
    left: -20px;
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
    z-index: 999;
}

//group by
.dropdown-groupby .group-by-custome-head {
    width: 134px;
}
.glo-group-btn-all-task {
    width: 60%;
}
.glo-group-btn-fiter {
    width: 40%;
    justify-content: flex-end;
    display: flex;
}
// .row * {
//     width: unset;
// }
.glo-diplay-row {
    display: flex;
    align-items: center;
}

.btn-show-hide {
    margin-left: 18px;
}

.size-text-tabs {
    font-size: 16px;
}

@media screen and (min-width: 375px) and (max-width: 900px) {
    .glo-group-btn-all-task {
        width: 100%;
    }
    .glo-group-btn-fiter {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
    .glo-group-btn-all-task {
        width: 60%;
    }
    .glo-group-btn-fiter {
        width: 40%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 375px) and (max-width: 500px) {
    .glo-diplay-col {
        flex-wrap: wrap;
    }
    .glo-btn-all-task {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .glo-group-btn-all-task {
        width: 60%;
    }

    .glo-group-btn-fiter {
        width: 40%;
        display: flex;
        justify-content: flex-end;
    }
}

.btn-reload {
    border: 1px solid #dfe1e6;
    padding: 5px 8px;
    border-radius: 4px;
    align-items: center;
    display: flex;
}

.btn-show-hide {
    border: 1px solid #dfe1e6;
    padding: 5px 8px;
    border-radius: 4px;
    align-items: center;
    display: flex;
}
