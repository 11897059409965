@import '../../components/GlobalStyles/GlobalStyles.scss';

.dropdown-toggle::after {
    content: '';
}

.dropdown-toggle {
    width: 100%;
    background: none;
}

ul#dropdown-menu {
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    border: none;
    min-width: 128px;
    font-size: 1.4rem;
}

.wrapper {
    width: 100%;
    padding: 5px 8px;
}

.status-item {
    padding: 6px 10px;
    cursor: pointer;
}

.status-item__Testing {
    // color: $color-btn-new;
    // &:hover {
    //     background: $background-btn-new;
    // }
}
.status-item__Inprogress {
    color: $color-tag-inProgress;
    &:hover {
        background: $background-btn-inProgress;
    }
}
.status-item__Closed {
    color: $color-btn-close;
    &:hover {
        background: $background-btn-close;
    }
}
.status-item__Pending {
    color: $color-btn-pending;
    &:hover {
        background: $background-btn-Pending;
    }
}
.status-item__Resolved {
    color: $color-btn-resolve;
    &:hover {
        background: $background-btn-resolve;
    }
}
.status-item__Testing {
    color: $color-btn-testing;
    &:hover {
        background: $background-btn-testing;
    }
}
