@import '../../components/GlobalStyles/GlobalStyles.scss';

.dropdown-toggle::after {
    content: '';
}

.dropdown-toggle {
    width: 100%;
    background: none;
}

ul#dropdown-menu {
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    border: none;
    min-width: 128px;
    font-size: 1.4rem;
    max-height: 203px;
    overflow-y: scroll;
}

.wrapper {
    width: 100%;
    padding: 5px 8px;
}

.user-item {
    padding: 4px 6px;
    cursor: pointer;

    &:hover {
        color: $color-primary;
        background: $background-color-hover-menu-item;
    }
}
