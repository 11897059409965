@import '../../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0 12px;
    height: $height-header;
    width: 100%;
    background: #fff;
    box-shadow: 0px 1px 3px rgba(119, 118, 118, 0.32);
}

.wrapper-menu {
    display: flex;
    height: 100%;
}

.menu-horizontal-header {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 78%;
}

.menu-horizontal-header__thumnail {
    margin-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color;
    font-weight: 600;
    cursor: pointer;
    & img {
        height: 35px;
        min-width: 35px;
        object-fit: cover;
    }
}

.menu-horizontal-wrap {
    height: 100%;
}

.menu-horizontal-list {
    height: 100%;
    display: flex;
    align-items: center;
}

.menu-horizontal-header__create {
    width: 65px;
    height: 32px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 4px;
    background-color: #0052cc;
}

//menu user
.menu-user {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px;
}

.menu-user__wrap-input {
    // position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #dfe1e6;
    border-radius: 6px;
    width: 330px;

    &:focus-within {
        border: 2px solid #4c9aff !important;
    }
}

.input-icon {
    margin: 8px;
}

.input-text {
    display: flex;
    align-items: center;
    width: calc(100% - 16px);
    height: 100%;

    & input {
        width: 100%;
        height: 100%;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.avatar-user {
    width: 24px;
    height: 24px;
    object-fit: unset;
}

.menu-user__item {
    margin-left: 18px;
}

.menu-user__icon {
    font-size: 24px;
}

//profile
.hn-dropdown-profile button {
    padding-top: 6px;
    background: #fff;
}

.hn-dropdown-profile .dropdown-toggle::after {
    display: none;
}

ul#hn-dropdown-menu {
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    border: none;
    min-width: 180px;
    font-size: 1.6rem;
}

ul#hn-dropdown-menu__avatar {
    width: 260px;
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    border: none;
    font-size: 1.6rem;
}

.project-item {
    padding: 6px 10px;
    cursor: pointer;

    &:hover {
        color: $color-primary;
        background: $background-color-hover;
    }
}

.hn-my-profile {
    display: flex;
    padding: 15px 10px 5px 20px;
}

.hn-my-profile a {
    padding: 0px;
}

span.header-text-span {
    padding-left: 10px;
}

.hn-my-profile a:hover {
    color: $color-primary;
    background: #fff;
}

.hn-info-profile {
    padding-left: 15px;
}

.btn-logout {
    color: #212529 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-family: 'Circular Std', sans-serif !important;
    width: 100%;
    display: flex !important;
    justify-content: flex-start !important;
}

.btn-logout:hover {
    background: #fff !important;
    color: #0052cc !important;
}

.input-text__close {
    height: 36px;
    background: none;
    padding-right: 4px;
    color: #5e6c84;
    font-size: 18px;
}

.hn-info-profile-username,
.hn-info-profile-email {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// loading
// .loading {
//     position: absolute;
//     right: calc(32px);
//     top: 50%;
//     color: rgba(22, 24, 35, 0.34);
//     animation: spinner 0.8s linear infinite;
// }

// @keyframes spinner {
//     from {
//         transform: translateY(-50%) rotate(0);
//     }
//     to {
//         transform: translateY(-50%) rotate(360deg);
//     }
// }
// .glo-width-header {
//     width: 1920px;
//     min-width: 1200px;
//     max-width: 2000px;
// }
@media screen and (min-width: 375px) and (max-width: 1000px) {
    .wrapper {
        height: 8.5rem;
    }
    .menu-user {
        justify-content: unset;
        flex: unset;
        margin-right: 0px;
    }
    .menu-horizontal-header {
        height: unset;
        width: 100%;
    }
    .menu-user__wrap-input {
        width: calc(100% - 50px);
    }
    .menu-user__item {
        width: 30px;
    }
}
@media screen and (min-width: 375px) and (max-width: 500px) {
    ul#hn-dropdown-menu__avatar {
        width: 290px;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1500px) {
    .menu-user {
        width: 40%;
        margin-right: 0px;
    }
}

@media screen and (min-width: 375px) and (max-width: 1000px) {
    .glo-btn-project-mobile {
        display: none;
    }
}

.btn-project {
    font-size: 1.2rem;
    padding: 4px 10px;
    border-radius: 16px;
    margin-right: 8px;
}

.btn-project-number {
    font-size: 1.4rem;
}

.wrap-btn-team {
    width: 170px;
    display: flex;
    justify-content: space-around;
}

.label-team {
    margin-left: 4px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}
