@import '../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 14px 42px 0;
}

.wrapper-content {
    padding: 10px;
}

.list-user-title {
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    line-height: 1.2;
    margin: 0px;
}

.list-user-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 80px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.table-content-title {
    display: flex;
    align-items: center;
    // color: #5e6c84;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
}

.list-project__item-select {
    text-align: center !important;
}

.list-project_item-name img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.list-project_item-button {
    display: flex;
    justify-content: center;
}

.list-project_item-button button {
    width: 80px;
    border-radius: 4px;
    color: #1c1c1c;
    padding: 4px 8px;
    font-size: 14px;
}

.list-project_item-button-add {
    width: 100px;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    background: #0052cc;
    margin-left: 25px;
}

.list-project_item-button-add:hover {
    color: #fff;
    // background: #2a7af1;
}

.text-center {
    text-align: center;
}

.list-user-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-user-title-top {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-user-search {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.list-user-search input {
    width: 250px;
}

// new
.list-user_table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list-user_title-table {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    font-weight: 600;
}

.list-user_item-body-tr {
    // border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    // border-right: 1px solid #d9d9d9;
    border-left: 3px solid #0065ff;
    display: flex;
    padding: 10px;
    margin-bottom: 5px;
}

.list-user_item-body-tr:hover {
    background-color: #f1f7fd;
}

// .list-user_title-table-id,
// .list-user_title-item-body-id {
//     width: 10%;
// }

.list-user_title-table-username,
.list-user_title-item-body-username {
    width: 25%;
}
.list-user_title-table-email,
.list-user_title-item-body-email {
    width: 25%;
}

.list-user_title-table-shortname,
.list-user_title-item-body-shortname {
    width: 15%;
}

.list-user_title-table-action,
.list-user_title-item-body-action {
    width: 25%;
}

.list-dayOff_title-table-username {
    width: 35%;
}
.list-dayOff_title-table-shortname {
    width: 30%;
}

button.btn-edit-user {
    background: #e9f9ea;
    color: #2ba229;
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 15px;
}

button.btn-delete-user {
    background: #ffe5e4;
    color: #e41e12;
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 14px;
}

.list-user-pagination {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
    margin-right: 5px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.profile-image-user {
    display: flex;
    justify-content: center;
    position: relative;
}

.profile-image-user img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile-icon-file {
    position: absolute;
    bottom: 0;
    right: 38%;
    background: #e0f5ff;
    border-radius: 100%;

    .profile-icon-file-upload {
        font-size: 18px;
    }
}

.txt-valid-password ul {
    margin-left: 15px;
}

.txt-valid-password ul li {
    color: #f17477;
    list-style-type: circle;
    font-size: 14px;
}

.ip-search-user {
    display: inline-block !important;
    margin-left: 12px;
}

@media screen and (min-width: 375px) and (max-width: 1199px) {
    .wrapper {
        padding: 14px 15px;
    }

    .list-user-table {
        width: 100%;
        overflow: auto;
    }

    .list-user_table {
        width: 920px;
    }

    .list-user_title-table-username,
    .list-user_title-item-body-username,
    .list-user_title-table-email,
    .list-user_title-item-body-email {
        width: 300px;
    }

    .list-user_title-table-shortname,
    .list-user_title-item-body-shortname {
        width: 120px;
    }

    .list-user_title-table-action,
    .list-user_title-item-body-action {
        width: 200px;
    }
    .glo-table-col {
        width: 200px;
    }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
    .list-user-content {
        flex-direction: column;
    }

    .list-user-title-top {
        width: 100%;
    }

    .list-user-search {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .list-user_title-table-username,
    .list-user_title-item-body-username {
        width: 30%;
    }
    .list-user_title-table-email,
    .list-user_title-item-body-email {
        width: 30%;
    }

    .list-user_title-table-shortname,
    .list-user_title-item-body-shortname {
        width: 15%;
    }

    .list-user_title-table-action,
    .list-user_title-item-body-action {
        width: 25%;
    }
    .glo-table-col {
        width: 200px;
    }
}

.profile-title {
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    line-height: 1.2;
    text-align: center;
}

.profile-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 160px;
    left: 170px;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.profile-title-create {
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    line-height: 1.2;
    text-align: center;
}

.profile-title-create::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 105px;
    left: 170;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.profile-title-edit {
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    line-height: 1.2;
}

.profile-title-edit::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 105px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.profile-image-user {
    display: flex;
    justify-content: center;
    position: relative;
}

.profile-image-user img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile-icon-file {
    position: absolute;
    bottom: 0;
    right: 38%;
    background: #e0f5ff;
    border-radius: 100%;

    .profile-icon-file-upload {
        font-size: 18px;
    }
}

.profile-control {
    display: flex;
    justify-content: flex-end;

    .btn-profile-update {
        height: 32px;
        color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
        border-radius: 4px;
        background-color: #0052cc;
    }

    .btn-profile-update:hover {
        color: #0052cc;
        background-color: #fff;
        border: 1px solid #0052cc;
    }

    .btn-profile-cancel {
        width: 65px;
        height: 32px;
        font-size: 1.4rem;
        font-weight: 500;
        border-radius: 4px;
        margin-left: 5px;
        background: #f17477;
        color: #fff;
    }

    .btn-profile-cancel:hover {
        background: #fff;
        color: #f17477;
        border: 1px solid #f17477;
    }

    .btn-profile-cancel:focus {
        box-shadow: unset;
    }
}

.profile-tab-text {
    font-size: 16px !important;
    text-transform: capitalize !important;
}
.glo-cursor-pointer {
    cursor: pointer;
}
.glo-padR-10 {
    padding-left: 10px;
}
.glo-marR-5 {
    margin-right: 5px;
}
.glo-table-col {
    width: 16.6%;
}

.btn-update-remaining {
    background: $color-primary;
    color: #fff;
    padding: 4px;
    border-radius: 6px;
}

.glo-table-col-icon {
    font-size: 18px;
    margin: 0 8px;
    cursor: pointer;

    &_edit {
        color: $color-primary;
    }

    &_delete {
        color: red;
    }
}

.form-input-search {
    width: 160px;
}

.wrap-input-radio {
    margin-left: 16px;
    cursor: pointer;

    & input {
        cursor: pointer;
    }

    & label {
        cursor: pointer;
        margin-left: 4px;
    }
}

.input-datetime {
    width: 200px;
    border: 1px solid #ced4da;
    padding: 4px 10px;
    margin-right: 24px;
    border-radius: 4px;
}

.btn-export {
    padding: 4px 10px;
    border-radius: 4px;
    background: $color-primary;
    color: #fff;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.msg-export {
    margin-left: 12px;
    color: #23b943;
}

.lable-format {
    padding-left: 10px;
}
