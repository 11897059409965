@import '../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 56px 42px 0;
}
.summary-title-page {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
    .summary-title {
        font-size: 2.07143em;
        font-style: inherit;
        line-height: 1.10345;
        letter-spacing: -0.01em;
        margin-top: 40px;
        color: $text-color;
        font-weight: 500;
    }
    .summary-p {
        color: #797c80;
        font-weight: 500;
        margin: 12px auto 0px;
        max-width: 700px;
        line-height: 24px;
        border-radius: 4px;
    }
}
.summary-body {
    display: flex;
    justify-content: center;
}
.summary-body__content {
    margin: 0px -8px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1096px;
    min-width: 1096px;
}

.glo-summary-col {
    min-width: 0px;
    height: 100px;
    flex: 1 1 0%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    .glo-summary-col__border {
        width: 100%;
    }
    .glo-summary-col__border:hover {
        box-shadow: var(--ds-shadow-overlay, 0px 8px 12px rgba(9, 30, 66, 0.13), 0px 0px 1px rgba(9, 30, 66, 0.31));
        cursor: pointer;
    }
    .glo-summary__icon {
        width: 38px;
        height: 38px;
        align-self: center;
        padding: 0px;
        background: #e3f2fd;
        border-radius: 50%;
        position: relative;
        .glo-out {
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 27%;
            font-size: 18px;
            color: #0d47a1;
        }
    }
    .glo-infomation-project {
        width: calc(100% - 48px);
        padding-left: 10px;
        & .count-status {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0.5rem !important;
        }
    }
}
.glo-summary-col__border {
    padding: 24px;
    border-radius: 8px;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--ds-surface-raised, #ffffff);
    transition: box-shadow 300ms ease 0s;
    box-shadow: var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px 1px rgba(9, 30, 66, 0.13));
}
.summary-body__chart {
    margin-top: 24px;
    margin-bottom: 24px;
}
.glo-note {
    align-self: center;
    padding-left: 15px;
}
.glo-note-content {
    margin-bottom: 10px;
    .glo-note-content__row {
        margin: 0px;
    }
    .glo-note__color {
        width: 20px;
        height: 20px;
    }
    .glo-note__name {
        width: calc(100% - 20px);
    }
    .glo-note__value {
        text-align: right;
    }
}
.glo-team {
    margin-top: 24px;
    .glo-team__title {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .glo-team__content {
        margin-bottom: 10px;
    }
    .glo-item-work__count {
        align-self: center;
        text-align: center;
    }
}
.glo-item-name {
    .glo-item-name__img {
        width: 60px;
        height: 50px;
    }
    .glo-item-name__img img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
    }
    .glo-item-name__name {
        width: calc(100% - 80px);
        align-self: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.glo-item-work {
    align-self: center;
    .glo-item-work__chart {
        width: calc(100% - 60px);
    }
    .glo-item-work__value {
        width: 50px;
    }
}
.glo-item-work__chart {
    display: flex;
    align-items: center;

    .glo-item-work__chart__background {
        background: $color-border-table;
        width: 100%;
        height: 7px;
        border-radius: 10px;
    }
}

.glo-item-work__chart__background__color {
    border-radius: 10px;
    transition: width 0.6s ease;
}

.glo-summary-row {
    color: #fff;
}

.card-inprogress {
    background: #679bff;
}

.card-new {
    background: #ff9d63;
}

.card-overdue {
    background: #a1221c;
}

.card-resolved {
    background: #4cbf36;
}

.count-status {
    font-weight: 700;
}

.count-status-text {
    font-weight: 500;
    font-size: 14px;
}

.wrap-chart {
    height: 80%;
    width: 100%;
}
