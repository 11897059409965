@import '../../components/GlobalStyles/GlobalStyles.scss';

.list-project__item-priority__icon-high {
    margin-right: 6px;
    color: $color-high;
    font-size: $icon-size-priority;
}

.list-project__item-priority__icon-medium {
    color: $color-medium;
    margin-right: 6px;
    font-size: $icon-size-priority;
}

.list-project__item-priority__icon-low {
    color: $color-low;
    margin-right: 6px;
    font-size: $icon-size-priority;
}
