@import '../../GlobalStyles/GlobalStyles.scss';

.menu-list {
    min-width: 160px;
}

.menu-item {
    display: flex;
    justify-content: flex-start;
    padding: 6px 10px;
    background: none;
    width: 100%;
    color: $text-color;

    &:hover {
        background: $background-color-hover-menu-item;
        cursor: pointer;
    }

    // &__text {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     font-size: 1.6rem;
    //     font-weight: 400;
    // }
}
