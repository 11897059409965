@import '../../../../components/GlobalStyles/GlobalStyles.scss';
.wrapper {
    margin: 0;
    padding: 26px;
    padding-top: 7px;
    font-size: 1.4rem;
    min-height: 800px;
    color: $text-color;
    overflow-y: auto;
    h1 {
        margin-top: 12px;
    }
}

.wrapper-page {
    margin: 0;
    padding: 26px;
    padding-top: 58px;
    font-size: 1.4rem;
    min-height: 800px;
    color: $text-color;
    overflow-y: auto;
    h1 {
        margin-top: 12px;
    }
}

.wrapper-detail-page {
    padding: 16px;
}

.wrapper-form {
    padding-right: 24px;
    overflow-y: auto;
}

// .wrapper-scroll {
//     max-height: 861px;
// }

.wrapper-form-project {
    display: flex;
    align-items: center;
}

.info-detail {
    padding: 0 !important;
    align-items: center;
    & img {
        margin-right: 8px;
    }
}

.wrapper-form__name {
    padding-left: 8px;
    font-size: 1.3rem;
    text-transform: uppercase;
}

.wrapper-form__title {
    font-weight: 600;
    margin-top: 18px;
    font-size: 2.4rem;
}

.content-description {
    padding-top: 24px;
}

.content-description__item {
    font-size: 1.5rem;
    font-weight: 600;
}

.content-description__text {
    font-size: 1.4rem;
    text-decoration: line-through;
}

.wrapper-form__icon {
    color: #65ba43;
    font-size: 1.8rem;
}

.title-task {
    margin-top: 8px;
    border: 1px solid #dfe1e6;
    padding: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &__icon {
        text-align: end;
    }
}

.wrapper-body-details {
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px 12px;
}

.body-details__item {
    margin-bottom: 12px;
}

.body-details__title {
    font-weight: 600;
}

// chart
.glo-item-work__chart {
    width: calc(100% - 60px);
}
.glo-item-work__value {
    width: 50px;
    margin-left: 12px;
}

.glo-item-work__chart {
    display: flex;
    align-items: center;
    .glo-item-work__chart__background {
        background: $color-border-table;
        width: 40%;
        height: 7px;
        border-radius: 10px;
    }
}
.glo-item-work__chart__background__color {
    border-radius: 10px;
}

.details__priority {
    height: 20px;
}

.body-details__time {
    margin-top: 12px;
    font-size: 1.2rem;
}

// .wrapper-editor {
//     border: 1px solid #dfe1e6;
// }

.content-description {
    min-height: 60px;
}

.form-comment {
    margin-top: 18px;
}

.avatar-task__user {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.wrapper-icon {
    height: 24px;
}

.btn-icon-item {
    background: none;
    padding: 0 9px;
    margin-left: 8px;
    color: #42526e;
    &:hover {
        background-color: #091e4214;
        cursor: pointer;
        border-radius: 4px;
    }
}

.icon-item {
    font-size: 2.4rem;
    width: 100%;
    font-weight: 500;
}

.wrapper-status-task {
    margin-top: 14px;
}

.status-task__item {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.status-task {
    padding: 0;
}

.wrapper-btn-add-create-child {
    flex: 1;
    text-align: end;
}

.btn-add-create-child {
    color: $text-color;
    padding: 4px 10px;
    font-size: 1.4rem;
    border-radius: 6px;
    &:hover {
        color: $color-primary;
    }
}

.btn-add-create-child__icon {
    margin-right: 6px;
}

.form-comment__title {
    padding-top: 18px;
    font-size: 1.6rem;
    font-weight: 600;
}

// comment

.comment-item {
    border: 1px solid #dfe1e6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 4px 6px;
    margin-top: 18px;
    font-weight: 600;
}

.comment-item__status {
    margin-right: 24px;
}

.comment-item__status-title {
    margin-right: 8px;
}

.comment-item__content {
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    & .content-editor {
        width: 100%;
        padding: 8px;
    }
}
.comment-item__save {
    display: none;
}

.comment-item-btn__edit {
    padding: 0px 8px;
    cursor: pointer;

    &:hover {
        color: $color-primary;
    }
}

.comment-item__save {
    padding: 0px 8px;
    cursor: pointer;

    // &:hover {
    //     color: $color-primary;
    // }
}

.comment-item-btn__cancel {
    margin-right: 24px;

    &:hover {
        color: $color-primary;
    }
}

.comment-item-btn__save {
    &:hover {
        color: $color-primary;
    }
}

.comment-item__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

// .comment-status,
// .comment-assign {
//     margin-top: 12px;
//     height: 34px;
// }

// .comment-status__select {
//     padding: 0;
// }

.comment-status__select,
.comment-assign__select {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 100%;
    padding: 0;

    & .comment-status__select-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
    }

    & .assign {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
    }
}

.list-priority {
    height: 100%;
    align-items: center;
}

.list-priority__item {
    margin-right: 12px;

    & label {
        margin-left: 4px;
    }
}

.input-completed {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 34px;
    padding: 8px;
    width: 82px;
    & + label {
        margin-left: 4px;
    }
}

.input-hours {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 34px;
    padding: 8px;
    width: 115px;
}

// .radio-low {
//     color: $color-low;
// }
// .radio-medium {
//     color: $color-medium;
// }
// .radio-high {
//     color: $color-high;
// }

// .comment-status__select-NEW {
//     border-color: $color-btn-new;
// }
.comment-status__select-OPEN {
    border-color: $color-btn-open;
}
.comment-status__select-CLOSE {
    border-color: $color-btn-close;
}
.comment-status__select-START {
    border-color: $color-btn-pending;
}
.comment-status__select-RESOLVE {
    border-color: $color-btn-resolve;
}

// .status__NEW {
//     color: $color-btn-new;
// }

.status__OPEN {
    color: $color-btn-open;
}

.status__CLOSE {
    color: $color-btn-close;
}

.status__START {
    color: $color-btn-pending;
}

.status__RESOLVE {
    color: $color-btn-resolve;
}

.list-name__check {
    padding: 0;
}

.comment-notify {
    margin-top: 18px;
}

.item-checkbox__name {
    margin-top: 6px;
}

.checkbox-name {
    margin-left: 8px;
    display: inline;
}

//insert
.body-detail_task_children {
    border: 1px solid #dfe1e6;
    border-radius: 6px;
    padding: 10px 8px;
}

.title-name-column {
    font-weight: 600;
}

tr.body-detail_task-tr {
    // border-left: 3px solid #ff5630;
    cursor: pointer;
    &:hover {
        color: $color-primary;
        background: #091e4214;
    }
    border-bottom: 1px solid #dfe1e6;
}
.glo-text-overflow {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.glo-star {
    width: 30px;
}
.glo-padding {
    padding: 5px 0px;
}
.glo-completed {
    text-align: center;
}
.glo-title-task {
    width: calc(100% - 30px);
}

.not-allow-input {
    background-color: #e8eaee;
    &:hover {
        cursor: not-allowed;
    }
}
.glo-detail-child-complete {
    width: 75px;
}
.glo-detail-child-status {
    width: 95px;
}
.glo-detail-child-assign {
    width: 140px;
}

.wrapper-icon-sort {
    display: flex;
    flex-direction: column;
    height: 32px;
    margin-left: 8px;
    margin-top: -5px;
}

.notify-icon-sort {
    font-size: 1.8rem;
}

.table-content-title__sort-icon {
    font-size: 1.8rem;
    cursor: pointer;
}

.glo-detail-child-title {
    width: 165px;
}
.glo-detail-child-tr {
    width: 100%;
    display: flex;
}
.glo-position-fixed-header {
    position: fixed;
    width: calc(100% - 291px);
    background: #fff;
    z-index: 10;
    height: 40px;
}
.glo-position-fixed-view {
    position: absolute;
    right: 35px;
    width: 530px;
}

.res-padding-zero {
    padding-left: 0px !important;
}

// .row > * {
//     margin: 0px !important;
// }

.res-create-task-child {
    display: flex;
    justify-content: flex-end;
}

.res-text-status {
    margin-right: 10px;
}

.align-items-center {
    align-items: center;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    .res-comment-editor {
        padding-right: 0px;
        padding-left: 0px;
    }

    .wrapper {
        padding: 10px;
    }

    .wrapper-form {
        padding-right: 10px;
        overflow-y: unset;
    }

    .res-priority {
        margin-top: 10px;
    }

    .res-image-user-comment {
        display: flex;
        justify-content: flex-start !important;
        margin-bottom: 10px;
    }

    .wrapper-btn-add-create-child {
        flex: unset;
        margin-left: 10px;
    }

    .comment-item__info {
        display: block;
    }

    .comment-item__status {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 5px;
    }

    .comment-item__assignee {
        display: block;
        margin-top: 5px;
    }

    .rest-text-detail {
        padding-left: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 375px) and (max-width: 575px) {
    .res-create-task-child {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .wrapper-btn-add-create-child {
        margin-left: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .res-text-hour {
        text-align: end;
    }

    .wrapper-form {
        padding-right: unset;
        overflow-y: unset;
    }
}

@media screen and (min-width: 375px) and (max-width: 1600px) {
    .body-detail_task_children {
        overflow: auto;
    }

    .detail_task_children-res {
        width: 500px;
    }

    .glo-position-fixed-header {
        width: 100%;
    }

    .glo-position-fixed-header {
        position: unset;
    }

    .wrapper-page {
        padding-top: 5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .res-btn-status {
        margin-left: 10px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 2000px) {
    .res-complete {
        width: 90px;
    }
    .res-hour {
        width: 70px;
    }
    .res-input-complete-hour {
        width: 30px;
    }
}

.icon-edit-point {
    font-size: 1.6rem;
    margin-left: 12px;
    cursor: pointer;

    &:hover {
        color: $color-primary;
    }
}

.input-edit-point {
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    padding: 2px;
}

.glo-body-scroll-detailPage {
    height: 190px;
    overflow: auto;
    display: block;
}
.glo-body-scroll-detail {
    height: 190px;
    overflow: auto;
    display: block;
    // width: calc(100% - 17px);
}
.glo-margin-0 {
    margin: 0px !important;
}
