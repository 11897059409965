.wrapper {
    padding: 56px 42px 42px;
}

.header-page-requirement {
    .head-title {
        text-transform: uppercase;
        font-size: 18px;
        position: relative;
        line-height: 1.2;
    }

    .head-title::before {
        content: '';
        position: absolute;
        top: 100%;
        width: 230px;
        left: 0;
        height: 3px;
        border-radius: 2px;
        background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
    }

    .btn-create-requirement {
        padding: 5px 10px;
        border-radius: 4px;
        background: #0052cc;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
    }
}

.table-content-head-title {
    border-bottom: 1px solid #dfe1e6;
}

.checked-item {
    color: #0052cc;
}

.border-top {
    border-top: 1px solid #ededed;
}
.border-bottom {
    border-bottom: 1px solid #ededed;
}
.border-left {
    border-left: 1px solid #ededed;
}
.border-right {
    border-right: 1px solid #ededed;
}
.glo-col {
    padding: 0px !important;
}
.glo-row {
    margin: 0px !important;
}

.thead-content-no,
.tbl-content-no {
    width: 4%;
}

.thead-content-classification,
.tbl-content-classification {
    width: 20%;
}

.thead-content-requirement,
.tbl-content-requirement {
    width: 28%;
}

.thead-content-task,
.tbl-content-task {
    width: 15%;
}

.thead-content-check,
.tbl-content-check {
    width: 7%;
}

.thead-content-memo,
.tbl-content-memo {
    width: 10%;
}

.thead-content-last-update,
.tbl-content-last-update {
    width: 8%;
}

.thead-content-creator,
.tbl-content-creator {
    width: 8%;
}

.thead-content-no,
.thead-content-classification,
.thead-content-requirement,
.thead-content-task,
.thead-content-check,
.thead-content-memo,
.thead-content-last-update,
.thead-content-creator,
.tbl-content-no,
.tbl-content-classification,
.tbl-content-requirement,
.tbl-content-task,
.tbl-content-check,
.tbl-content-memo,
.tbl-content-last-update,
.tbl-content-creator {
    padding: 0px;
}

.text-ellip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.head-project {
    display: flex;
    flex-direction: row;
    align-items: center;

    .rqm-project-requirementname {
        margin-right: 1rem;
        align-items: center;
        margin-bottom: 0px !important;
    }

    .btn-add-require-chklist {
        background: #dbe9ff;
        color: #0052cc;
        padding: 5px 12px;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 8px;
    }

    .btn-delete-require-chklist {
        background: #ffe5e4;
        color: #e41e12;
        padding: 5px 12px;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 8px;
    }
}

.item-task-link {
    text-decoration: underline !important;
    color: #0052cc;
    cursor: pointer;
}

.requirement-last-border:last-child {
    border-bottom: 1px solid #dfe1e6;
}

.task-require-ckl:last-child i {
    display: none;
}

.icons-check {
    color: #2ba229;
}

.icons-uncheck {
    color: #b2b4b8;
}

.requirement-last-border:last-child {
    border-bottom: 1px solid #dfe1e6;
}

.item-task-link {
    text-decoration: underline;
    color: #0052cc;
}

.requirement-last-border:last-child {
    border-bottom: 1px solid #dfe1e6;
}

.task-require-ckl:last-child i {
    display: none;
}

.checked-item {
    color: #0052cc;
}

.border-top {
    border-top: 1px solid #ededed;
}
.border-bottom {
    border-bottom: 1px solid #ededed;
}
.border-left {
    border-left: 1px solid #ededed;
}
.border-right {
    border-right: 1px solid #ededed;
}
.glo-col {
    padding: 0px !important;
}
.glo-row {
    margin: 0px !important;
}
.glo-height {
    height: 50%;
}
.glo-height-100 {
    height: 100%;
}

.table-content-body-data:last-child {
    border-bottom: 1px solid #ededed;
}

.height-50 {
    height: 50px;
}

.height-100 {
    height: 100%;
}

.item-padd {
    align-items: center;
    display: flex;
    padding: 10px;
    min-height: 50px;
    // height: 100%;
}

.height-100-percent {
    height: 100%;
}

.icons-check {
    color: #2ba229;
}

.icons-uncheck {
    color: #b2b4b8;
}

.font-weight-600 {
    font-weight: 600;
}

// .table-content-body {
//     height: 720px;
//     overflow: auto;
// }

.contentCenter {
    justify-content: center;
}

@media screen and (min-width: 375px) and (max-width: 1600px) {
    .require-res-table-scroll {
        width: 100%;
        overflow: auto;
    }

    .require-res-table {
        width: 1650px;
    }
}

.width-task-100 {
    width: 100%;
    word-wrap: break-word;
}

.height-item-require {
    height: 100%;
}

.alignCenter {
    align-items: center;
}
