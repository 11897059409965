@import '../../components/GlobalStyles/GlobalStyles.scss';

.login-page {
    margin: 0;
    padding: 0;
    background: linear-gradient(180.63deg, #478bd6 -11.82%, rgba(255, 255, 255, 0) 174.19%);
    font-size: 16px;
}
.wrapper-login {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container {
    max-width: 450px;
    width: 100%;
    background: #fff;
    flex-grow: 1;
    padding: 30px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    min-height: 450px;
    border-radius: 12px;
}

img.image-logo {
    display: flex;
    width: 250px;
    margin: 0 auto;
}

.login-title h2 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.text-forgot-password a {
    color: $color-primary;
    font-size: 14px;
}

.hn-label-checkbox {
    font-size: 14px;
}

button.btn.btn-login {
    background: $color-primary;
    width: 100%;
    color: #fff;
}

button.btn.btn-login {
    background: $color-primary;
    width: 100%;
    color: #fff;
    padding: 5px 0px;
    border-radius: 4px;
}

@media (min-width: 375px) and (max-width: 576px) {
    .login-page {
        background: #fff;
    }
    .login-container {
        box-shadow: none;
    }
}

.wrapper-message {
    height: 12px;
    margin: 4px 0px 12px;
}

.wrapper-btn-submit {
    margin-bottom: 8px;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    margin: 0;
}

.message-sent {
    color: #12be46;
    font-size: 1.4rem;
    margin: 0;
}

.wrapper-input {
    padding: 10px 20px !important;
}

.forgot-password {
    height: 86px;
    display: flex;
    justify-content: end;
    align-items: end;
    font-size: 14px;
    color: #737373;

    & span:hover {
        cursor: pointer;
        color: #c0bbbb;
    }
}
