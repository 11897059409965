.wrapper {
    padding: 56px 42px 42px;
}

.header-page-requirement {
    .head-title {
        text-transform: uppercase;
        font-size: 18px;
        position: relative;
        line-height: 1.2;
    }

    .head-title::before {
        content: '';
        position: absolute;
        top: 100%;
        width: 230px;
        left: 0;
        height: 3px;
        border-radius: 2px;
        background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
    }
}
.btn-create-requirement {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}
.table-content-head-title {
    border-bottom: 1px solid #dfe1e6;
    // margin-bottom: 3px;
}

.tbl-content-no,
.require-tbl-id {
    width: 4%;
}

.tbl-content-checkbox,
.require-tbl-checkbox {
    width: 2%;
    padding: 0px;
    text-align: center;
}

.tbl-content-classification,
.require-tbl-classification {
    width: 26%;
}

.tbl-content-requirement,
.require-tbl-requirement {
    width: 35%;
}

.tbl-content-task,
.require-tbl-task {
    width: 15%;
}

.tbl-content-check,
.require-tbl-check {
    width: 6%;
}

.tbl-content-memo,
.require-tbl-memo {
    width: 12%;
}

.require-tbl-id,
.require-tbl-classification,
.require-tbl-requirement,
.require-tbl-task,
.require-tbl-check,
.require-tbl-memo {
    padding: 0px;
}

.requirement-last-border:last-child {
    border-bottom: 1px solid #dfe1e6;
}

.item-task-link {
    text-decoration: underline !important;
    color: #0052cc;
    cursor: pointer;
}

.requirement-last-border:last-child {
    border-bottom: 1px solid #dfe1e6;
}

.task-require-ckl:last-child i {
    display: none;
}

.checked-item {
    color: #0052cc;
}

.border-top {
    border-top: 1px solid #ededed;
}
.border-bottom {
    border-bottom: 1px solid #ededed;
}
.border-left {
    border-left: 1px solid #ededed;
}
.border-right {
    border-right: 1px solid #ededed;
}
.glo-col {
    padding: 0px !important;
}
.glo-row {
    margin: 0px !important;
}
.glo-height {
    height: 50%;
}
.glo-height-100 {
    height: 100%;
}

.table-content-body-data:last-child {
    border-bottom: 1px solid #ededed;
}

.select-item-acctive {
    background: #ccc;
}

.select-item-deacctive {
    background: #fff;
}

.checked-item {
    color: #0052cc;
}

.not-checked-item {
    color: #f00;
}

.btn-edit-requirement-chklist {
    background: #e9f9ea;
    color: #2ba229;
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 15px;
}

.btn-requirement-chklist {
    background: #ffe5e4;
    color: #e41e12;
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 14px;
}

.height-50 {
    height: 50px;
}

.height-100 {
    height: 100%;
}

.item-padd {
    align-items: center;
    display: flex;
    // padding: 10px;
}

.height-100-percent {
    height: 100%;
}

.icons-check {
    color: #2ba229;
}

.icons-uncheck {
    color: #b2b4b8;
}

.font-weight-600 {
    font-weight: 600;
}

.contentCenter {
    justify-content: center;
}

.btn-merge {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}

.text-ellip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.icons-add-task {
    align-self: center;
    align-items: center;
    display: flex;
}
.icons-add-task-req {
    width: 24px;
}

@media screen and (min-width: 375px) and (max-width: 1600px) {
    .require-res-table-scroll {
        width: 100%;
        overflow: auto;
    }

    .require-res-table {
        width: 1650px;
    }
}
.glo-input-none {
    display: none;
}
.glo-cursor-pointer {
    cursor: pointer;
}
.glo-cursor-not-allowed {
    cursor: not-allowed;
}

.option-custom {
    position: relative;
}

.select-input {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ced4da;
    // padding: 0 10px;
    position: relative;
    // height: 42px;
    border-radius: 4px;
}

.select-input--multiple {
    height: auto;
    // min-height: 42px;
    flex-wrap: wrap;
}

.select-click {
    flex: 1;
    height: 42px;
}

// .select-input::before {
//     position: absolute;
//     content: '';
//     right: 2px;
//     top: 18px;
//     border-top: 5px solid #252525;
//     border-right: 5px solid transparent;
//     border-left: 5px solid transparent;
// }

.select-icon {
    font-size: 20px;
    margin-right: 5px;
    color: #84ce3f;
    flex-basis: 30px;
}

.select-title {
    color: #252525;
}

.select-title.placeholder {
    color: #ededed;
}

.select-list {
    border: 1px solid #ededed;
    border-top: none;
    max-height: 260px;
    overflow: auto;
    position: absolute;
    background: white;
    z-index: 10;
}

.select-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

.select-item:hover {
    background: #ededed;
}

.selected-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 29px;
    width: 100%;
    position: relative;
}

.selected-item {
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ededed;
    border-radius: 3px;
    margin: 2px;
    width: calc(100% - 24px);
}

.glo-title-task {
    width: calc(100% - 24px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selected-item span {
    margin-right: 5px;
}

.width-select-task {
    width: 100%;
}

.glo-icon-select {
    position: absolute;
    right: 2px;
    top: 5px;
}
#select {
    display: none;
}
.glo-margin-right {
    margin-right: 5px;
}
.glo-padding-10 {
    padding: 10px;
}
.glo-margin-0 {
    margin-bottom: 0px !important;
}
.glo-content-center {
    justify-content: center;
}
.glo-row {
    display: flex;
    flex-direction: row;
}
.tbl-content-checkbox-add {
    width: 6%;
}
.glo-row {
    margin: 0px !important;
}
.glo-center {
    display: flex;
    justify-content: center;
    height: 100%;
}
// .table-content-add:last-child {
//   border-top: unset;
// }
.border-bottom {
    border-bottom: 1px solid #ededed;
}
.glo-display-center {
    height: 100%;
    align-self: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.glo-cell-no-color {
    color: #000000;
}
.glo-cell-color {
    color: #0d6efd;
}
