@import '../../../../components/GlobalStyles/GlobalStyles.scss';

.create-project-action {
    display: flex;
    justify-content: flex-end;
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.dialog-add-project-content {
    width: 100%;
    padding: 0px 20px;
}

.hn-color-red {
    color: $color-high;
}

.lbl-name_add-user {
    padding-left: 7px;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

.lbl-search {
    padding-right: 5px;
}

.project-add_table,
.project-add_user-in-table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.project-add_title-table {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    background: #deebff;
}

.project-add_item-body-tr {
    border-bottom: 1px solid #d9d9d9;
    border-left: 3px solid #f17477;
    display: flex;
    padding: 10px;
    margin-bottom: 5px;
}

.project-add_check-user,
.project-add_item-check-user {
    width: 5%;
}

.project-add_title-username,
.project-add_item-username {
    width: 50%;
}

.project-add_title-shortname,
.project-add_item-shortname {
    width: 15%;
}

.project-add_title-email,
.project-add_item-email {
    width: 30%;
}

.project-user_title-in-table {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    // width: 100%;
    background: #deebff;
}

.project_item-body-in-pro {
    border-bottom: 1px solid #d9d9d9;
    border-left: 3px solid #f17477;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 5px;
}

.project-add_check-user input {
    margin-left: 3px;
}

.project-username-title-in-pro {
    width: 100%;
}

.project_item-username-in-pro {
    justify-content: flex-start;
    width: 92%;
}

.project_item-delete-user {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 24px;
    height: 24px;
    color: #a1221c;
    background-color: rgba(255, 0, 0, 0.23);
    text-align: center;
    border-radius: 100%;
}

.project-list-user-tb {
    max-height: 480px;
    overflow: auto;
}

//css thanh cuon
.project-list-user-tb::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
}

.project-list-user-tb::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0;
    border-radius: 10px;
}

.project-list-user-tb::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.9, #deebff),
        color-stop(0.1, rgb(97, 186, 255))
    );
}

@media screen and (min-width: 375px) and (max-width: 991px) {
    .user-table-res {
        width: 100%;
        overflow: auto;
    }

    .project-add_table {
        width: 900px;
    }

    .project-add_check-user,
    .project-add_item-check-user {
        width: 50px;
    }

    .project-add_title-username,
    .project-add_item-username,
    .project_item-username-in-pro {
        width: 300px;
    }

    .project-add_title-shortname,
    .project-add_item-shortname {
        width: 150px;
    }

    .project-add_title-email,
    .project-add_item-email {
        width: 300px;
    }

    .mb-table-user-res {
        margin-bottom: 20px;
    }

    .project-add_user-in-table {
        width: 900px;
    }

    .project_item-delete-user {
        width: 30px;
    }

    .user-table-res::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #f5f5f5;
    }

    .user-table-res::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
        background-color: #f0f0f0;
        border-radius: 10px;
    }

    .user-table-res::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0.9, #deebff),
            color-stop(0.1, rgb(97, 186, 255))
        );
    }
}
