.wrapper {
    padding: 56px 120px 56px 56px;
}

.wrapper-btn-close {
    padding: 12px;
    align-items: center;
    justify-content: space-between;

    & h2 {
        margin: 0;
    }
}

.btn-icon-item {
    display: flex;
    align-items: center;
    background: none;
    color: #42526e;
}

.icon-item {
    font-size: 2.4rem;
    &:hover {
        background-color: rgba(9, 30, 66, 0.0784313725);
        cursor: pointer;
        border-radius: 50%;
    }
}

.input-path-file {
    border: 1px solid #ccc;
    margin-left: 24px;
    padding: 4px;
    border-radius: 6px;
    min-width: 630px;
}

.wrapper-btn-create {
    padding: 10px;
    justify-content: flex-end;
}

.btn-icon-create-item {
    margin-right: 8px;
    color: #fff;
    background-color: #0052cc;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
}
