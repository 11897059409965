@import '../../../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 56px 42px 42px;
}

// .dialog-create-task {
//     padding: 35px 10px 20px 30px;
// }

.create-task-title {
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    line-height: 1.2;
}

.create-task-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 105px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.dialog-create-task-content {
    width: 100%;
    padding: 0px 20px;
}

.hn-color-red {
    color: $color-high;
}

.hn-upload-file {
    border: 1px dashed #d9d9d9;
}

.hn-upload-file label {
    display: flex;
    justify-content: center;
}

.hn-upload-file-attach label {
    width: 100%;
    max-width: unset;
    border: 1px dashed #d9d9d9;
    display: flex;
    justify-content: center;
}

.create-task-action {
    display: flex;
    justify-content: flex-end;
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.hn-upload-file-attachment span {
    font-size: 16px;
}

.hn-upload-file-attachment button {
    font-size: 14px;
    text-transform: capitalize;
}
.hn-upload-file-attachment svg {
    font-size: 64px;
}

.none-resize {
    resize: none;
}

// due date
.dropdown-duedate {
    position: relative;
}

ul.hn-drop-duedate {
    padding: 12px 10px;
    width: 100%;
}

.btn-dropdown-duedate {
    padding: 6px 16px;
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    color: #000 !important;
    border: 1px solid #d9d9d9;
    text-align: left;
}

.btn-dropdown-duedate:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.btn-dropdown-duedate:hover {
    border: 1px solid #d9d9d9;
}

.btn-dropdown-duedate .dropdown-toggle::after {
    display: none;
}

svg.hn-icon-drop-end {
    float: right;
    margin-top: 4px;
}

ul.hn-drop-duedate li a {
    font-size: 14px;
    padding: 10px 0px 10px 10px;
}

li.hn-datepicker {
    padding: 6px 10px;
    font-size: 14px;
}

p.dropdown-item {
    font-size: 14px;
    padding: 0px 10px;
}

.customDatePickerWidth .react-datepicker {
    font-size: 1.2rem !important;
}

input[name='Low'] {
    accent-color: $color-low;
}

input[name='Medium'] {
    accent-color: #ab7c1c;
}

input[name='High'] {
    accent-color: $color-high;
}

.hn-margin-radio {
    margin-right: 10px;
}

// .input-container-picker {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
// }

// .input-container-picker .react-datepicker-wrapper {
//     margin: 12px 0;
//     width: 100%;
// }

// .input-container-picker .react-datepicker-wrapper input {
//     padding: 4px 12px;
// }

// .input-container-picker .css-j5h6pi-MuiPopper-root {
//     margin-left: 90px !important;
// }

.input-container-picker input {
    font-size: 14px;
}

.input-container-picker .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

.hn-assign-task {
    display: flex;
}

input.form-control.input-to-assign-task {
    border: 1px solid #d9d9d9;
    height: 30px;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
    width: 200px;
}

.form-check.hn-assign-task_item {
    // width: calc(100% - 200px);
    margin-right: 29px;
}

.lbl-name_item-assign-task {
    margin-left: 7px;
}

.sp-create-assignee-to {
    background: #bde7ff;
    padding: 8px;
    border-radius: 25px;
}

.sp-create-project-name,
.sp-create-title,
.sp-create-assignee-to,
.sp-create-priority {
    margin-left: 8px;
}
.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

.input-started {
    margin-left: 4px;
    cursor: pointer;
}

.res-assign-task {
    display: flex;
    align-items: center;
}
