@import '../../components/GlobalStyles/GlobalStyles.scss';

.wrapper {
    padding: 56px 42px 0;
}

.btn-create-milestone {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.create-task-title {
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    line-height: 1.2;
}

.create-task-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 105px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.dialog-create-task-content {
    width: 100%;
    padding: 0px 20px;
}

.hn-color-red {
    color: $color-high;
}

.create-task-action {
    display: flex;
    justify-content: flex-end;
}

.hn-btn-cancel {
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 14px;
}

.hn-btn-submit {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 10px;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

.switch-text {
    font-size: 1.4rem;
    margin-left: -5px;
}

.kanban-view-wrapper {
    margin-left: 12px;
}

.milestone-switch {
    width: unset !important;
}
.glo-milestone-col {
    margin-right: 25px;
    margin-left: 1px;
    width: 310px;
    margin-top: 5px;
    border: 1px solid #dddddd;
    background: #ffffff;
}
.glo-milestone-header {
    background: #f4f5f7;
    padding: 10px;
}
.glo-milestone-header-title {
    align-items: center;
}

.glo-milestone-header-title * {
    width: unset;
}
.glo-kanbanBug-completed-background-color {
    border-radius: 10px;
}
.glo-kanbanBug-completed-background {
    background: $color-border-table;
    width: 100%;
    height: 5px;
    border-radius: 10px;
}
.glo-kanbanBug-completed {
    width: 70%;
}
.glo-item-work__value {
    width: 30%;
}
.glo-close-color {
    align-items: center;
}
.glo-milestone-body {
    padding: 10px;
    overflow-y: auto;
}
.glo-milestone-body-item {
    background: #f9fff5;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
}
.glo-icon-mesgin * {
    width: unset;
}

//milestone task
.menu-task-milestone {
    font-size: 14px;
}

.milestone-task-item-li svg {
    font-size: 20px;
}

.milestone-task-item-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 12px;
    cursor: pointer;
}

.milestone-task-item-li:hover {
    background: #deebff;
    color: #0052cc;
}

span.sp-item-li {
    padding-left: 7px;
    align-items: center;
}

.milestone-task-icon {
    color: #0052cc;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
}

.milestone-task-icon-2 {
    color: #0052cc;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.milestone-task-icon-check {
    color: rgb(101, 186, 67);
    margin-left: 8px;
    cursor: pointer;

    &:hover {
        color: rgb(80, 109, 69);
    }
}

.dialog-add-task-milestone {
    width: 100%;
    padding: 0px 20px;
}

.milestone-add-search-title {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.ip-search-title {
    width: 250px;
}

.lbl-search {
    padding-right: 5px;
}

.milistone-task-add_table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.milistone-task-add_title-table {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    background: #deebff;
}

.milistone-task-add_check-task {
    width: 5%;
}
.milistone-task-add_title-task {
    width: 10%;
}

.milistone-task-add_title-title {
    width: 50%;
}

.milistone-task-add_title-status {
    width: 15%;
}

.milistone-task-add_title-due-date {
    width: 20%;
}

.project-add_item-body-tr {
    max-height: 480px;
    overflow-y: auto;
}

.milistone-task-add_item-body-tr {
    border-bottom: 1px solid #d9d9d9;
    border-left: 3px solid #f17477;
    display: flex;
    padding: 10px;
    margin-bottom: 5px;
}

.milistion-input-check-all {
    margin-left: 3px;
}

.milestone-item-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.milestone-item-right-icon {
    margin-right: 6px;
}

.glo-milestone-body {
    height: 500px;
}

//percent css
.glo-item-work__chart {
    align-items: center;
    justify-content: flex-end;
    width: 140px;
    .glo-item-work__chart__background {
        background: $color-border-table;
        width: 75%;
        height: 6px;
        border-radius: 10px;
    }
}
.glo-item-work__chart__background__color {
    border-radius: 10px;
}

.wrapper-title {
    display: flex;
}

.title {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.text-assignTo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
}

.dialog-create-milestone {
    padding: 0px 20px;
}

.icon-dropdown {
    font-size: 1.8rem;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        color: rgb(168, 64, 168);
    }
}
@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .kanban-view-wrapper {
        margin-left: 0px;
    }
}

@media screen and (min-width: 375px) and (max-width: 1900px) {
    .wrapper-milestone-column {
        width: 100%;
        overflow: auto;
    }

    .milestone-scroll {
        width: 1500px;
    }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
    .wrapper {
        padding: 56px 15px 0;
    }
}

@media screen and (min-width: 375px) and (max-width: 576px) {
    .btn-create-milestone {
        margin-left: 8px;
    }
}
