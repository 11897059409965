@import '../GlobalStyles/GlobalStyles.scss';

.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    padding: 6px 10px;
    border-radius: 99px;
    color: $text-color;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
}

.list-project__item-status__New {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-new;
    background-color: #ff00003d;
}

.list-project__item-status__Inprogress {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-open;
    background-color: $background-btn-inProgress;
}

.list-project__item-status__Closed {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-close;
    background-color: $background-btn-close;
}
.list-project__item-status__Close {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-close;
    background-color: $background-btn-close;
}
.list-project__item-status__Pending {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-pending;
    background-color: $background-btn-Pending;
}

.list-project__item-status__Resolved {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-resolve;
    background-color: $background-btn-resolve;
}

.list-project__item-status__TestDone {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-tag-test-done;
    background-color: $background-btn-TestDone;
}

.list-project__item-status__Testing {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-testing;
    background-color: $background-btn-Testing;
}
.list-project__item-status__Fixing {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-fixbug;
    background: $background-btn-Fixbug;
}
.list-project__item-status__TestFail {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-testfail;
    background: $background-btn-testfail;
}
.list-project__item-status__FixDone {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-tag-test-done;
    background-color: $background-btn-TestDone;
}
.list-project__item-status__Fixbug {
    cursor: text;
    justify-content: center;
    font-size: 1.1rem;
    color: $color-btn-fixbug;
    background: $background-btn-Fixbug;
}

.table-content-title__date {
    justify-content: center;
    font-weight: 500;
    min-width: 60px;
    font-size: 1.2rem;
    color: $text-color;
    background-color: #dfe1e6;
    cursor: pointer;
}

.icon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 2.4rem;
}

.icon + .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.4rem;
    margin-left: 8px;
}

.title + .icon {
    margin-left: 8px;
}

.btn-form__save {
    margin-right: 8px;
    color: #fff;
    background-color: $color-primary;
    font-weight: 500;
    font-size: 1.4rem;
}

.btn-form__cancel {
    font-weight: 500;
    font-size: 1.4rem;
}

.mr8 {
    margin-right: 8px;
}
.btnCustomItemStatus {
    border-top-right-radius: 25px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 0px !important;
}

.spText-title {
    display: flex;
    align-items: center;
}
.radius8px {
    border-radius: 8px;
}

.cursorText {
    cursor: text !important;
}

.fs1rem {
    font-size: 1rem;
}

.fs12px {
    font-size: 1.2rem;
}

.pd4 {
    padding: 4px;
}

.button-delete {
    justify-content: center;
    font-weight: 500;
    min-width: 60px;
    font-size: 1.2rem;
    color: $color-btn-new;
    background-color: $background-btn-new;
    cursor: pointer;
}

.minWidth72px {
    min-width: 72px;
}

.fontWeight600 {
    font-weight: 600;
}

.padding2And8 {
    padding: 2px 8px;
}
