@import '../../../../components/GlobalStyles/GlobalStyles.scss';

.editor-class {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-radius: 4px;
    min-height: 120px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    line-height: 2.4rem;
}

.wrapper-form__edit {
    padding: 0;
}

.form-edit {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-radius: 4px;
    min-height: 90px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    line-height: 2.4rem;
    height: unset;
}

.form-edit_comment {
    background-color: #fff;
    padding: 1rem;
    border-top: unset;
    border-radius: 4px;
    min-height: 90px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    line-height: 2.4rem;
    height: unset;
}

.form-edit-description {
    border: 1px solid #dfe1e6;
    border-top: unset;
}

.display-wrapper {
    display: none;
}

.toolbar-class {
    border: 1px solid #dfe1e6;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: #000;
    &:hover {
        color: #000;
    }
}

input[name='Low'] {
    accent-color: $color-low;
}

input[name='Medium'] {
    accent-color: $color-medium;
}

input[name='High'] {
    accent-color: $color-high;
}
.glo-wrapper {
    margin: 0;
    padding: 10px;
    font-size: 1.4rem;
    color: #172b4d;
}
.glo-dialog {
    overflow: hidden;
}
.glo-scroll {
    overflow-y: auto;
    height: 800px;
}
.wrapper-class-addKanbanBug {
    height: 100%;
    padding: 0px;
}
