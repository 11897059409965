.wrapper {
    // padding: 8px 0px 0px 30px;
}

.switch-text {
    font-size: 1.4rem;
    margin-left: -5px;
}

.kanban-view-wrapper {
    margin-left: 12px;
}
