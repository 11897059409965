@import '~/../../../../../components/GlobalStyles/GlobalStyles.scss';
.btn-icon-item {
    background: none;
    padding: 0 3px;
    margin-left: 8px;
    &:hover {
        background-color: #091e4214;
        cursor: pointer;
        z-index: 2;
        // border-radius: 4px;
    }
}

.kanban-detail-page {
    width: 1620px;
}

.icon-item {
    font-size: 17px;
    width: 100%;
    font-weight: 450px;
}
.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
    min-width: 100%;
}
.glo-dialog-kanban {
    min-width: 1500px;
}
.glo-dialog-kanban-detail {
    width: 1870px;
}
.glo-button-add-kanban {
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px;
}
.glo-row {
    margin: 0px !important;
}
.rdw-editor-wrapper {
    box-sizing: border-box !important;
}
.glo-padd {
    padding: 0px 10px;
}
.wrapper-class {
    height: 100% !important;
}
.glo-index-dialog {
    z-index: 900 !important;
    margin: 0px -20px !important;
}
.hn-color-red {
    color: $color-high;
}
.glo-padding-10 {
    padding-left: 10px;
}
.glo-no-padding {
    padding: 0px;
}
.glo-padd-editor {
    padding: 0px 18px;
}
//kanbanbug
.est-hours {
    width: 60px;
}

.wrapper-title {
    width: calc(100% - 60px);
}
.glo-kanbanBug-completed-background-color {
    border-radius: 10px;
}
.glo-kanbanBug-completed-background {
    background: $color-border-table;
    width: 100%;
    height: 5px;
    border-radius: 10px;
}
.glo-kanbanBug-completed {
    width: 110px;
}
.glo-img-fancybox {
    width: 80px;
    height: 80px;
    margin-right: 5px;
}
.input-completed {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 34px;
    padding: 8px;
    width: 60px;
    & + label {
        margin-left: 4px;
    }
}
.glo-detail-margin-bottom {
    margin-bottom: 1rem;
}
.glo-detail-margin-left {
    padding-left: 20px;
}
.glo-border {
    border: 1px solid #dfe1e6;
    padding: 8px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.glo-border-detail {
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px 12px;
}
.body-details__item {
    margin-bottom: 12px;
}
.title-task__icon {
    text-align: end;
}
.glo-item-work__value {
    width: calc(100% - 150px);
}
.glo-center {
    align-self: center;
}
.avatar-task__user {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 8px;
}
.glo-img-fancybox-detail {
    width: 80px;
    height: 80px;
    margin-right: 38px;
    position: relative;
}
.glo-button-detele-detail {
    position: absolute;
    /* top: 55px; */
    top: -11px;
    right: -25px;
    color: red;
    background: #e9ecef;
    width: 22px;
    border-radius: 100%;
}

.glo-button-detele-detail svg {
    font-size: 17px;
}

.glo-button-detele-detail:hover {
    background: #d7e5f3;
}

.glo-update-file {
    padding: 5px;
    margin-bottom: 2px;
    align-items: center;
}
.glo-update-file-file {
    width: calc(100% - 22px);
}

.glo-update-file-button {
    width: 22px;
    margin-left: 0px;
    color: red;
    background: #e9ecef;
    border-radius: 100%;
}

.glo-update-file-button:hover {
    background: #d7e5f3;
}

.glo-button-delete-bug-kanban {
    z-index: 10;
}
.glo-list-bug-margin-top {
    margin-top: 10px;
}

// comment

.comment-item {
    border: 1px solid #dfe1e6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 4px 6px;
    margin-top: 18px;
}

.comment-item__status {
    margin-right: 24px;
}

.comment-item__status-title {
    margin-right: 8px;
}

.comment-item__content {
    border: 1px solid #dfe1e6;
    border-top: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    & .content-editor {
        width: 100%;
        padding: 8px;
    }
}
.comment-item__save {
    display: none;
}

.comment-item-btn__edit {
    padding: 0px 8px;
    cursor: pointer;

    &:hover {
        color: $color-primary;
    }
}

.comment-item__save {
    padding: 0px 8px;
    cursor: pointer;
}

.comment-item-btn__cancel {
    margin-right: 24px;

    &:hover {
        color: $color-primary;
    }
}

.comment-item-btn__save {
    &:hover {
        color: $color-primary;
    }
}

.comment-item__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.comment-status,
.comment-assign {
    margin-top: 12px;
    height: 34px;
}

.comment-status__select,
.comment-assign__select {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 100%;
    padding: 0;

    & .comment-status__select-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
    }

    & .assign {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
    }
}

.list-priority {
    height: 100%;
    align-items: center;
}

.list-priority__item {
    margin-right: 12px;

    & label {
        margin-left: 4px;
    }
}

.input-completed {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 34px;
    padding: 8px;
    width: 60px;
    & + label {
        margin-left: 4px;
    }
}

.input-hours {
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    height: 34px;
    padding: 8px;
    width: 48px;
}

.comment-status__select-OPEN {
    border-color: $color-btn-open;
}
.comment-status__select-CLOSE {
    border-color: $color-btn-close;
}
.comment-status__select-START {
    border-color: $color-btn-pending;
}
.comment-status__select-RESOLVE {
    border-color: $color-btn-resolve;
}

.status__OPEN {
    color: $color-btn-open;
}

.status__CLOSE {
    color: $color-btn-close;
}

.status__START {
    color: $color-btn-pending;
}

.status__RESOLVE {
    color: $color-btn-resolve;
}

.list-name__check {
    padding: 0;
}

.comment-notify {
    margin-top: 18px;
}

.item-checkbox__name {
    margin-top: 6px;
}

.checkbox-name {
    margin-left: 8px;
    display: inline;
}

.error-message {
    color: #ff0000;
    font-size: 1.4rem;
    padding-left: 10px;
}

.btn-form__create {
    color: #fff;
    background-color: #0052cc;
    font-weight: 500;
    font-size: 1.4rem;
}

.hn-comment-row {
    margin: 10px 0px 0px 0px;
}

.hn-comment-row-content {
    margin: 0px;
}
.glo-inform-comment {
    display: flex;
    flex-direction: column;
}
.glo-margin-title {
    margin: 0px 10px !important;
}
.glo-button-image {
    width: 100px;
    border: 1px solid #ededed;
    border-radius: 4px;
}
.list-file {
    display: none;
}
.glo-filexcel {
    display: none;
}

.hn-detail_lbl-status {
    display: flex;
    align-items: center;
}
.glo-padding-18 {
    padding: 0px 18px;
}
.glo-padding-none {
    padding: 0px !important;
}

.custom-title-ellipsis {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-bug-assign {
    padding: 0px 18px;
    display: flex;
    align-items: center;
}

.lbl-assign-bug {
    width: 120px;
}

.assign-bug-icon-add svg {
    font-size: 20px;
}

.title-bug-item-ellip {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-bug-item {
    padding: 0px;
    text-align: end;
}

.description-bug-item {
    color: #666666;
    font-size: 14px;
    padding: 5px 0px;
}

.description-bug-item p {
    margin-bottom: 0px !important;
}
.assign-bug-icon-add {
    width: 30px;
}

.glo-scroll-dialog {
    overflow: auto;
    max-height: 800px;
    // padding-bottom: 2rem;
}
.glo-text-overflow {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.glo-table-assign-Task {
    width: 30%;
    margin: 0;
    padding: 0;
}
.glo-group-child {
    width: 70%;
}
.glo-table-assign-Bug {
    width: calc(40% - 2px);
}
.glo-table-assign-Assing {
    width: 40%;
}
.glo-table-assign-completed {
    width: 20%;
}
.glo-border-table-assign {
    border: 1px solid #dfe1e6;
    border-radius: 6px;
}
.glo-header-table-assign {
    padding: 8px 6px;
    border-bottom: 1px solid #dfe1e6;
}
.glo-table-body-assign-tr {
    border-bottom: 1px solid #dfe1e6;
    align-items: center;
}
.glo-table-body-assign-tr:last-child {
    border-bottom: unset;
}
.glo-body-assign-Task {
    border-right: 1px solid #dfe1e6;
}

.glo-padding-bottom-none {
    margin-bottom: 0px !important;
}
.glo-group-child-body {
    padding: 0px;
}
.text-align-center {
    align-items: center;
    align-self: center;
}
.color_New {
    color: #a1221c;
}
.color_FixDone {
    color: #ff00ff;
}
.color_Fixing {
    color: #45cb70;
}
.color_Testing {
    color: #65b1b1;
}
.color_TestFail {
    color: #dba124;
}
.color_Close {
    color: #2c363c;
}
.glo-border-task-assign {
    border-top: 1px solid #dfe1e6;
    padding: 3px;
}
.glo-border-task-assign:last-child {
    border-bottom: 1px solid #dfe1e6;
}
.glo-hover:hover {
    color: rgb(0, 82, 204);
    background: #f4f5f7;
}
.glo-body-assign-Task-div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: calc(100% - 2px);
    padding: 0;
}
.glo-status-color_1 {
    width: 2px;
    height: 100%;
    background: #a1221c;
    padding: 0;
    margin: 0;
}
.glo-status-color_2 {
    width: 2px;
    height: 100%;
    background: #45cb70;
    padding: 0;
    margin: 0;
}
.glo-status-color_3 {
    width: 2px;
    height: 100%;
    background: #ff00ff;
    padding: 0;
    margin: 0;
}
.glo-status-color_4 {
    width: 2px;
    height: 100%;
    background: #65b1b1;
    padding: 0;
    margin: 0;
}
.glo-status-color_5 {
    width: 2px;
    height: 100%;
    background: #dba124;
    padding: 0;
    margin: 0;
}
.glo-status-color_6 {
    width: 2px;
    height: 100%;
    background: #2c363c;
    padding: 0;
    margin: 0;
}

.glo-status-color-child_1 {
    width: 2px;
    height: 35px;
    background: #a1221c;
    padding: 0;
    margin: 0;
}
.glo-status-color-child_2 {
    width: 2px;
    height: 35px;
    background: #45cb70;
    padding: 0;
    margin: 0;
}
.glo-status-color-child_3 {
    width: 2px;
    height: 35px;
    background: #ff00ff;
    padding: 0;
    margin: 0;
}
.glo-status-color-child_4 {
    width: 2px;
    height: 35px;
    background: #65b1b1;
    padding: 0;
    margin: 0;
}
.glo-status-color-child_5 {
    width: 2px;
    height: 35px;
    background: #dba124;
    padding: 0;
    margin: 0;
}
.glo-status-color-child_6 {
    width: 2px;
    height: 35px;
    background: #2c363c;
    padding: 0;
    margin: 0;
}
.glo-border-file {
    border-top: 1px solid #dfe1e6;
    border-left: 1px solid #dfe1e6;
    border-right: 1px solid #dfe1e6;
}
.glo-border-file:last-child {
    border-bottom: 1px solid #dfe1e6;
}
.glo-align-self-center {
    align-self: center;
}
.glo-btn-delete-assing-bug-mb {
    display: none;
}

.btn-delete-text-assign-bug {
    font-size: 16px;
    padding: 5px;
    border-radius: 6px;
}

@media screen and (min-width: 375px) and (max-width: 1000px) {
    .glo-dialog-kanban {
        min-width: unset;
    }
    .glo-dialog-kanban-detail {
        width: unset;
    }
    .glo-header-kanban {
        width: 100%;
    }
    .glo-body-kanban {
        width: 100%;
        overflow: auto;
    }
    .glo-body-kanban-content {
        width: 1828px;
    }
    .glo-height-droppable {
        height: unset !important;
    }
    .glo-height-content {
        height: unset !important;
    }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    .txt-name-file {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .glo-btn-delete-assing-bug-mb {
        display: block;
    }
    .glo-btn-delete-assing-bug-pc {
        display: none;
    }

    .res-delete-assign-bug {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
@media screen and (min-width: 375px) and (max-width: 991px) {
    .res-delete-assign-bug {
        margin-top: 10px;
    }
}

@media screen and (min-width: 375px) and (max-width: 1600px) {
    .table_task_children {
        overflow: auto;
    }

    .list_task_children-res {
        width: 500px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .text-pl-10 {
        padding-left: 10px !important;
    }

    .text-completed {
        width: 100px;
    }
}

@media screen and (min-width: 1601px) {
    .text-pl-10 {
        padding-left: 0px !important;
    }

    .text-completed {
        width: 100px;
    }

    .text-center-res {
        text-align: center;
    }
}
.glo-text-ellipsis,
.glo-text-ellipsis * {
    width: 245px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.glo-diplay-block {
    margin: 0px !important;
    display: block;
}
