.wrapper-review-form {
    width: 100%;
    padding: 20px 50px;
}

.review-form-title {
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    line-height: 1.2;
}

.review-form-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 120px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.rev-form-fill {
    border: 1px solid #d9d9d9;
    // padding: 35px 20px 35px 32px;
    padding: 30px 20px 15px 20px;
    border-radius: 8px;
}

.rev-information-staff {
    border-radius: 8px;
    background-color: #ececec;
    margin: 22px 0px;
}

.glo-row {
    margin-left: 0px;
    margin-right: 0px;
}

.rev-title {
    color: #065592;
    line-height: 1.1;
    font-weight: 600;
}

.hn-btn-submit {
    padding: 4px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}

.hn-btn-submit:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.rev-txt-employee {
    margin-bottom: 0px;
}

.font-weight-600 {
    font-weight: 600;
}

.btn-review-cancel {
    padding: 4px 10px;
    border-radius: 4px;
    background: #f0f0f0;
    color: #1c1c1c;
    font-size: 14px;
    text-transform: capitalize;
}

//dialog notify review

.FormQuestion_notice-table-review__kkrAl {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 14px 20px;
}

.notice-table-review {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.table-review-head {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    font-weight: 600;
    background: #deebff;
}

.table-review-body-scroll {
    max-height: 480px;
    overflow: auto;
}

.table-review-body {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    padding: 10px;
    margin-bottom: 5px;
}

.tbl-head-check-user,
.tbl-item-check-user {
    width: 10%;
}

.tbl-head-username,
.tbl-item-username {
    width: 25%;
}

.tbl-head-email,
.tbl-item-email {
    width: 25%;
}

.notice-oops {
    padding: 100px 0px;
}

.notice-oops-text {
    font-family: montserrat, sans-serif;
    font-size: 120px;
    margin: 0;
    font-weight: 900;
    left: 50%;
    background: url(../../../../assets/images/oops/bg-oops.jpg) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
}

.notice-duedate {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}

.btn-create-questions {
    padding: 5px 10px;
    margin-left: 18px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}

.btn-delete-questions {
    padding: 5px 10px;
    border-radius: 4px;
    background: #bd1e1e;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

@media screen and (min-width: 375px) and (max-width: 1000px) {
    .wrapper-review-form {
        padding: 20px 10px;
    }

    .notice-oops-text {
        font-size: 100px;
    }
}

.wrap-questions {
    margin-left: 16px;
    margin-bottom: 0;
}

.group-questions {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.input-new-questions {
    width: 83%;
}

.wrap-level {
    display: flex;
    width: 14%;
}

.wrap-level-icon {
    width: 3%;

    & .wrap-level-icon__close:hover {
        color: red;
        cursor: pointer;
    }
}

.from-level,
.to-level {
    width: 32px;
    border: 1px solid #ced4da;
    margin-left: 8px;
    padding-left: 8px;
    border-radius: 4px;
}

.from-level {
    margin-right: 12px;
}

.btn-icon-add {
    cursor: pointer;
    margin-left: 8px;
    &:hover {
        color: #04d137;
    }
}

.btn-icon-delete {
    cursor: pointer;
    margin-left: 8px;
    &:hover {
        color: red;
    }
}

.btn-icon-edit {
    cursor: pointer;
    margin-left: 8px;
    &:hover {
        color: #0052cc;
    }
}

.font-text-area {
    font-size: 13px !important;
}

.input-group-question {
    border: 1px solid #ccc;
    width: 78%;
}

.input-order-number {
    width: 48px;
    border: 1px solid #ccc;
    padding-left: 4px;
    margin-right: 6px;
}

.wrap-question {
    display: flex;
    margin-bottom: 12px;
}

.wrap-questions-new {
    display: flex;
    margin-bottom: 12px;
}

.text-group-name {
    color: #0052cc;
    text-decoration: underline;
}

.level {
    border: 1px solid #0065ff;
    background-color: #e9f2ff;
    border-radius: 50%;
    padding: 0 4px;
    width: 26px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
}

.wrapper-level {
    min-height: 25px;
    margin-bottom: 4px;
}

.wrap-level {
    display: flex;

    & .level {
        margin-right: 12px;
        border: 1px solid #0065ff;
        background-color: #e9f2ff;
        border-radius: 50%;
        padding: 0 4px;
        max-width: 100px;
        min-width: 26px;
        text-align: center;
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
            opacity: 0.4;
        }
    }

    & .level-active {
        background-color: #0052cc;
        border: 1px solid #0052cc;
        color: #fff;
    }
}

.rev-answer {
    margin-bottom: 8px;
    margin-top: 8px;
}
