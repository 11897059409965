@import '../../../src/components/GlobalStyles/GlobalStyles.scss';

.board-colume-kanban-view {
    width: 100%;
    overflow: auto;
}

.glo-item-work__chart {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -28px;
    .glo-item-work__chart__background {
        background: $color-border-table;
        width: 35%;
        height: 4px;
        border-radius: 10px;
    }
}
.glo-item-work__chart__background__color {
    border-radius: 10px;
}

.wrapper-title {
    display: flex;
}

.title {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.est-hours {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.est-hours__text {
    margin-left: 4px;
}

.no-due-date {
    font-size: 1.2rem;
    color: #665f5f;
    // text-decoration: line-through;
}

.wrapper-filter {
    display: flex;
    justify-content: flex-end;
    padding-right: 42px;
    margin-top: -32px;
}

.text-assignTo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
}
