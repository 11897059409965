@import '../../components/GlobalStyles/GlobalStyles.scss';

$margin-top-header: 10px;

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    // overflow-y: hidden;
}

.container-body {
    margin-top: $height-header;
    background-color: #e4e5e8;
}

.wrapper-body {
    margin-top: $margin-top-header;
}

.content {
    min-height: calc(100vh - $height-header - $margin-top-header);
    // margin: 0 20px 0 calc($width-sidebar + 20px);
    background-color: #fff;
}
// .glo-width-content {
//     max-width: 1800px;
//     min-width: 1000px;
//     width: 1630px;
// }

.spacer-header {
    height: $margin-top-header;
    background: #e4e5e8;
    z-index: 10;
    position: fixed;
    top: 56px;
    width: 100%;
}
.glo-layout-sidebar {
    width: $width-sidebar;
}
.glo-layout-content {
    width: calc(100% - $width-sidebar - 20px);
    margin-left: calc($width-sidebar + 20px);
    transition: 0.5s;
}
.glo-row {
    margin: 0px !important;
}
@media screen and (min-width: 375px) and (max-width: 1000px) {
    .glo-layout-sidebar {
        width: 100%;
    }
    .glo-layout-content {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
    .container-body {
        margin-top: 7rem;
    }
    .wrapper-body {
        margin-top: 30px;
    }
}
