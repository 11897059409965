@import '../../components/GlobalStyles/GlobalStyles.scss';

// .wrapper {
//     padding: 42px 42px 0;
// }

.header-in-list-task {
    // position: fixed;
    // overflow: hidden;
    background: #fff;
    // width: calc(100% - 27.5rem);
    padding: 4px 42px 0;
    width: 100%;
}

.content-in-list-task {
    padding: 0px 42px 0px 42px;
}

.list-task-count-status {
    width: calc(100% - 400px);
    justify-content: flex-end;
    display: flex;
}

.list-report {
    display: flex;
    flex-direction: column;

    .list-report__item {
        display: flex;
        text-align: left;
    }
}

.wrapper-menu-status {
    margin-bottom: 6px;
}

.wrapper-menu-filter {
    margin-bottom: 8px;
}

.list-info-filter-groupBy {
    // margin-bottom: 12px;
}

.table-content {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        padding: 8px;
        text-align: left;
        color: $text-color;
        border-bottom: 1px solid $color-border-table;
        font-weight: 450;
    }

    td {
        border-left: 1px solid $color-border-table;
        border-top: 1px solid $color-border-table;
    }
}

.table-content-title {
    display: flex;
    align-items: center;
    color: #5e6c84;
    font-weight: 500;
    cursor: text;
    // &:hover {
    //     color: $color-primary;
    // }
}

.table-content-title__fix {
    width: 10%;
}

.table-content-title__icon {
    font-size: 2rem;
    margin-right: 4px;
}

.table-content-title__rightIcon {
    margin-left: 4px;
}

.title-project {
    padding: 0 !important;
    color: rgb(37, 36, 36) !important;
    font-weight: 600 !important;
    border-bottom: unset !important;
    border-left: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
}

.title-project-date {
    color: #0052cc !important;
    font-size: 1.2rem;
    font-weight: 500 !important;
}

.list-project__item-select {
    width: 120px;
}

.list-project__item-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-project__item-select-icon {
    display: flex;
    flex-direction: row;
    margin-right: 12px;
}

.list-project__item-title {
    cursor: pointer;
    // max-width: 570px;
    width: 100%;
    word-break: break-all;
    // &:hover {
    //     color: $color-primary;
    // }
}

.title-description:hover {
    color: $color-primary;
}

.list-project__item-title-wrapper {
    display: inline-block;
    width: 100%;
    p {
        margin: 0;
    }
}

.list-project__item-title-icon {
    color: #4376c1;
    // margin-left: 8px;
    // background: $color-primary;
}

.table-content-title__sort {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    padding-left: 8px;
}

.table-content-title__sort-icon {
    font-size: 1.4rem;
    cursor: pointer;
    // padding: 0 10px;
    &:hover {
        color: $color-primary;
    }
}

.list-project__item-title-text {
    font-size: 1.3rem;
    color: #8d8b8b;
}

.table-content-title__thumbnail {
    display: flex;
    align-items: center;
    white-space: nowrap;

    & img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
        // object-fit: cover;
        min-width: 24px;
        min-height: 24px;
        cursor: inherit;
    }
}

// .table-content-title__date {
//     background-color: #dfe1e6;
//     padding: 2px 12px;
//     border-radius: 4px;
// }

.icon-more {
    background: transparent;
}

// .table-title-project + .list-project__item-space {
//     display: none ;
// }

.list-project__item-high {
    border-left: 3px solid $color-high;
}

.list-project__item-medium {
    border-left: 3px solid $color-medium;
}

.list-project__item-low {
    border-left: 3px solid $color-low;
}

.list-project__item-space {
    height: 16px;
}

.list-project__item-priority {
    display: flex;
    align-items: center;
}

.list-project__item-space td {
    border-left: none;
    border-bottom: none;
}

// panigation
.list-panigation {
    padding: 12px 0 10px 0;
}

.results-panigation {
    font-size: 1.4rem;
}

// no match filter
.no-match-filter {
    & td {
        text-align: center;
        vertical-align: middle;
        border-left: none;
        border-bottom: none;
        padding-top: 26px;
        color: $color-primary;
    }
}
.glo-icon-title {
    width: 70px;
    cursor: text;
}

.glo-type {
    padding: 4px;
    width: 150px;
}
.glo-type-body {
    padding: 4px;
    width: 150px;
}

.glo-father {
    padding: 4px;
    width: 30px;
    justify-content: center;
}

.glo-task {
    padding: 4px;
    width: 65px;
}

.glo-title {
    padding: 4px;
    width: 520px;
}

.glo-status {
    padding: 4px;
    width: 110px;
}

.glo-assigned {
    padding: 4px;
    width: 170px;
}
.glo-assigned-bug {
    padding: 4px;
    width: 170px;
}
.glo-width-100 {
    width: 100%;
}
.glo-due-date {
    padding: 4px;
    width: 110px;
}

// .glo-close-date {
//     padding: 4px;
//     width: 145px;
// }

.glo-priority {
    padding: 4px;
    width: 200px;
}

.glo-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.glo-header-table {
    border-bottom: 1px solid #dfe1e6;
    margin-bottom: 3px;
}
.glo-border-top {
    border-top: 1px solid #dfe1e6;
}
.glo-border-bottom {
    border-bottom: 1px solid #dfe1e6;
}
.glo-border-right {
    border-right: 1px solid #dfe1e6;
}
.glo-border-left {
    border-left: 1px solid #dfe1e6;
}
.glo-text-overflow {
    white-space: nowrap;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.wrapper-title-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
}

.glo-text-overflow-title {
    white-space: nowrap;
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.glo-margin-none {
    margin-bottom: 0px !important;
}
// .glo-margin-bottom {
//     margin-bottom: 3px;
// }
.glo-text-color {
    color: #172b4d;
}
.glo-center-div {
    display: flex;
    justify-content: center;
    width: 100%;
}
.glo-center-status {
    justify-content: center;
}
.glo-margin-right {
    margin-right: 15px;
    cursor: pointer;
}
.glo-text-weight {
    font-weight: 500;
    color: $text-color;
}
.glo-padd-none {
    padding: 0px !important;
}
.glo-margin-left {
    margin-left: 23px !important;
}
.glo-type-child {
    width: 127px;
}
.glo-hover-title:hover {
    color: $color-primary;
}
.glo-item-work__chart__background {
    background: $color-border-table;
    width: 50px;
    height: 7px;
    border-radius: 10px;
}
.glo-icon-completed {
    width: 105px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    cursor: text;
}
.glo-icon-completed-color {
    border-radius: 10px;
}
.glo-icon-completed-value {
    margin-left: 5px;
    width: 48px;
}

.child-icon-padding {
    padding-left: 5px;
}
.glo-icon-child-up {
    display: none;
}
.glo-cursor {
    cursor: pointer;
}
.glo-background-child {
    background-color: #f1f7fd;
}
.glo-margin-bottom-child {
    margin-bottom: 8px;
}
.glo-list-number-bug {
    width: calc(100% - 295px);
    padding-right: 0px;
}
.glo-number-bug-new {
    color: $color-btn-new;
}
.glo-number-bug-fix {
    color: $color-btn-fixbug;
}
.glo-number-bug-fix-done {
    color: $color-tag-test-done;
}
.glo-number-bug-testing {
    color: $color-btn-testing;
}
.glo-number-bug-test-fail {
    color: $color-btn-testfail;
}
.glo-number-bug-close {
    color: $color-btn-close;
}
.glo-row-bug {
    margin: 0px !important;
    padding: 0px !important;
}
.glo-button-bug {
    width: 30px;
}
.glo-number-bug {
    padding: 0px !important;
    width: calc(100% - 30px);
}
.glo-center-bug {
    align-items: center;
}
.glo-width-100 {
    width: 100%;
}
.glo-font-size-bug {
    font-size: 14px;
}

.title-task-parent {
    width: 192px;
    // width: calc(100% - 330px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fontSize-14 {
    font-size: 14px;
}

.glo-icon-bug {
    // width: calc(100% - 365px);
    width: 158px;
}
.glo-number-bug-font-size {
    font-size: 14px;
}
.glo-no-border {
    border-top: unset !important;
}

.table-content__due-date {
    justify-content: center;
    font-weight: 500;
    min-width: 60px !important;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 5px 8px;
    border-radius: 15px;
}

.task-duedate-after-next-week {
    color: #1c1c1c;
    background: #dfe1e6 !important;
}

.hn-content-center-due-date {
    display: flex;
    justify-content: center;
}

.hn-content-center {
    display: flex;
    justify-content: center;
}

.txt-close-due-date {
    display: block;
    text-align: center;
}
.glo-background-status-closed {
    background: #f7f7f7;
}
.glo-center-status {
    // height: 100%;
    display: flex;
    flex-direction: column;
    // align-self: center;
}

.wrapper-kanban-view {
    padding: 2rem;
}

.switch-view-padding {
    padding: 0px 20px;
}
.glo-body-table {
    // height: calc(100vh - 237px);
    overflow: auto;
}
.list-task-switch-view {
    width: 400px;
}
.glo-panigation-group {
    padding: 0px 42px;
}

.hn-panigation-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.hn-pagnition-mr {
    margin-right: 10px;
}

.tooltip-text-assign-bug:last-child span i {
    display: none;
}

.text-assign-bug-ellip {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}

.text-pl-asigneed-bug {
    padding-left: 5px;
}

@media screen and (min-width: 375px) and (max-width: 1000px) {
    .list-task-switch-view {
        width: 100%;
        padding: 0px !important;
    }
    .list-task-count-status {
        width: 100%;
        display: unset;
        margin-top: 5px;
        padding: 0px !important;
    }
    .glo-list-btn-filter {
        margin-top: 5px;
    }
    .glo-table-scroll {
        width: 100%;
        overflow: auto;
    }
    .glo-table {
        width: 1650px;
    }
    .glo-due-date {
        width: 200px;
    }
    .header-in-list-task {
        // position: fixed;
        // overflow: hidden;
        background: #fff;
        // width: calc(100% - 27.5rem);
        padding: 16px 10px 0;
        width: 100%;
    }
    .glo-panigation-group {
        padding: 0px 10px;
    }
    .content-in-list-task {
        padding: 0px 10px;
    }
    .list-info-filter-groupBy {
        display: none;
    }

    .hn-overflow-description {
        width: 200px;
    }

    .glo-priority {
        padding: 4px;
        width: 200px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1600px) {
    .glo-panigation-group {
        padding: 0px 10px;
    }
    .list-task-switch-view {
        width: 100%;
        padding: 0px !important;
    }
    .list-task-count-status {
        width: 100%;
        display: unset;
        margin-top: 5px;
        padding: 0px !important;
    }
    .glo-table-scroll {
        width: 100%;
        overflow: auto;
    }
    .glo-table {
        width: 1650px;
    }
    .glo-due-date {
        width: 200px;
    }
    .glo-list-btn-filter {
        margin-top: 5px;
    }
    .header-in-list-task {
        background: #fff;
        padding: 16px 10px 0;
        width: 100%;
    }
    .content-in-list-task {
        padding: 0px 10px;
    }

    .hn-overflow-description {
        width: 200px;
    }

    .glo-priority {
        padding: 4px;
        width: 200px;
    }
}
@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .header-in-list-task {
        background: #fff;
        padding: 16px 10px 0;
        width: 100%;
    }
    .content-in-list-task {
        padding: 0px 10px;
    }
    .glo-panigation-group {
        padding: 0px 10px;
    }
    .list-task-count-status {
        width: calc(100% - 300px);
        justify-content: flex-end;
        display: flex;
    }
    .list-task-switch-view {
        width: 300px;
    }
}

.comma-assign-bug:last-child span i {
    display: none;
}

@media screen and (min-width: 1601px) and (max-width: 1900px) {
    .glo-table-scroll {
        width: 100%;
        overflow: auto;
    }

    // .glo-table {
    //     width: 1650px;
    // }

    .list-task-switch-view {
        width: 300px;
    }

    .list-task-count-status {
        width: calc(100% - 300px);
        justify-content: flex-end;
        display: flex;
    }
}

.point-task,
.level-task {
    margin-left: 12px;
    border: 1px solid #0065ff;
    background-color: #e9f2ff;
    border-radius: 50%;
    padding: 0 4px;
    max-width: 100px;
    min-width: 26px;
    text-align: center;
    font-size: 1.4rem;
}

.hn-overflow-description {
    margin-bottom: 0px !important;
}

.hn-overflow-description p {
    display: flex;
    flex-direction: row;
}

.hn-overflow-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 395px;
    margin-bottom: 0px !important;
}

.mr-description-task p {
    margin-bottom: 0px !important;
}
