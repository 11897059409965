@import '../../components/GlobalStyles/GlobalStyles.scss';
.wrapper-review-form {
    width: 100%;
    padding: 20px 50px;
}

.review-list-content {
    padding: 30px 20px 15px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.review-form-title {
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    line-height: 1.2;
}

.review-form-title::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 120px;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background-image: linear-gradient(132.6deg, rgba(71, 139, 214, 1) 23.3%, rgba(37, 216, 211, 1) 84.7%);
}

.rev-title {
    color: #065592;
    line-height: 1.1;
    font-weight: 600;
}

.text-center {
    text-align: center;
}

.list-user-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

// start table
.list-user_table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list-user_title-table {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    font-weight: 600;
}

.list-user_item-body-tr {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    padding: 10px;
    margin-bottom: 5px;
}

.list-user_item-body-tr:hover {
    background-color: #f1f7fd;
}

.table-head-username,
.tbl-body-username {
    width: 25%;
}
.table-head-email,
.tbl-body-email {
    width: 20%;
}

.table-head-member-rv,
.tbl-body-member-rv {
    width: 15%;
}

.table-head-leader-rv,
.tbl-body-leader-rv {
    width: 15%;
}

.table-head-review-date,
.tbl-body-review-date {
    width: 20%;
}

.table-head-action,
.tbl-body-action {
    width: 20%;
}

.tbl-body-member-rv,
.tbl-body-leader-rv {
    text-align: center;
}

.tbl-body-review-date,
.tbl-body-action {
    padding-left: 15px;
}

//end table

button.btn-view-review {
    background: #f0f0f0;
    color: #1c1c1c;
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 15px;
}
button.btn-review-user {
    background: #e9f9ea;
    color: #2ba229;
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 15px;
}

.glo-cursor-pointer {
    cursor: pointer;
}

.hn-btn-submit, .btn-create-questions {
    padding: 5px 10px;
    border-radius: 4px;
    background: #0052cc;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-left: 12px;
}

.hn-align-center {
    align-items: center;
}

@media screen and (min-width: 375px) and (max-width: 1400px) {
    .list-user-table-content {
        width: 100%;
        overflow: auto;
    }

    .list-user_table {
        width: 1400px;
    }

    .table-head-username,
    .tbl-body-username {
        width: 25%;
    }
    .table-head-email,
    .tbl-body-email {
        width: 20%;
    }

    .table-head-member-rv,
    .tbl-body-member-rv {
        width: 15%;
    }

    .table-head-leader-rv,
    .tbl-body-leader-rv {
        width: 15%;
    }

    .table-head-review-date,
    .tbl-body-review-date {
        width: 20%;
    }

    .table-head-action,
    .tbl-body-action {
        width: 20%;
    }
}